import React from 'react';
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from '../hooks/useAuth';

export const AuthenticatedRoute = ({ children }) => {
  let location = useLocation();
  const { currentUser } = useAuth();

  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};
