export const AspectImage = props => {
	const Tag = props.tag || 'span';
	const aspect = props.aspect || '1/1';

	return <>
		<Tag className={`tw-aspect-[var(--aspect)] tw-overflow-clip tw-inline-flex tw-justify-center tw-items-center${ props.className ? " || " + props.className : '' }`} style={{ '--aspect': aspect }}>
			{ props.src ? <>
				<img className="tw-flex tw-w-full tw-h-full tw-object-contain tw-object-center" src={ props.src } alt={ props.alt || null } />
			</> : <>
				<span className="tw-select-none tw-text-6 tw-text-center tw-pointer-events-none tw-font-quaternary tw-font-quaternary-ultra tw-uppercase tw-leading-[.8] tw-tracking-tight">
					<span className="tw-text-[.7em]">
						Image Unavailable
					</span>
				</span>
			</> }
		</Tag>
	</>
}
