const Wrapper = (props) => {
	const Tag = props.tag || 'div';
	const attributes = {
		...props,
		tag: null,
		className: null
	}
	return (
		<Tag { ...attributes } className={`tw-w-full tw-max-w-1440px tw-mx-auto tw-flex tw-flex-col tw-px-15px md:tw-px-40px${ props.className ? " || " + props.className : '' }`}>
			{ props.children }
		</Tag>
	);
}

export default Wrapper;
