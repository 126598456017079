import { AuthContext } from '../contexts/auth';
import { useContext } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate, useLocation } from 'react-router-dom';

import axios from 'axios';
import { useAppContext } from '../contexts/app';

export const useAuth = () => {
	const { setLoggedTime, setCurrentTime } = useAppContext();
	const { currentUser, setCurrentUser } = useContext(AuthContext);
	const navigate = useNavigate();
	const { state } = useLocation();

	const login = async (response) => {
		// With Google's new OAuth2 API, when using implict flow, we now must make
		// a separate call to get the user's info.
		const timestamp = new Date().toISOString();
		localStorage.setItem('loggedTime', timestamp);
		setLoggedTime(timestamp);

		const userInfo = await axios.get(
			'https://www.googleapis.com/oauth2/v3/userinfo',
			{ headers: { Authorization: `Bearer ${response.access_token}` } },
		);

		setCurrentUser({ credentials: response, userinfo: userInfo.data });

		// Navigate to the page the user was on before logging in
		navigate(state?.from ? state.from : '/');
	};

	const logout = () => {
		setCurrentUser(null);
		setLoggedTime(null);
		localStorage.removeItem('loggedTime');
		localStorage.removeItem('timeDifference');
	};

	// Must use useGoogleLogin instead of GoogleLogin because GoogleLogin doesn't support implicit flow
	const signInWithGoogle = useGoogleLogin({
		onSuccess: login,
		onError: errorResponse => console.log(errorResponse),
		onNonOAuthError: errorResponse => console.log(errorResponse),
		flow: 'implicit',
		scope: 'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid',
	});

	return { signInWithGoogle, currentUser, login, logout };
};
