import React from "react";
import { PageHeader } from "../../../components/headers";
import { useAuth } from '../../../hooks/useAuth';
import { useAppContext } from "../../../contexts/app";
import { ButtonLight } from "../../../components/ctas";

export default function Login() {
	const { signInWithGoogle } = useAuth();
	const { currentTime } = useAppContext();


	return currentTime ? <>
		<div className="tw-space-y-30px tw-pb-60px tw-text-center tw-flex-grow tw-flex tw-flex-col tw-justify-center tw-items-center">
			<div className="tw-space-y-20px">
				<PageHeader className="tw-text-primary">
					Get Started
				</PageHeader>
				<ButtonLight default onClick={ signInWithGoogle }>
					<span className="tw-px-40px tw-py-10px tw-text-3">
						Login
					</span>
				</ButtonLight>
			</div>
		</div>
	</> : <>
		Loading
	</>
}
