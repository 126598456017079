import { useContext, createContext, useMemo, useState } from "react";

const AppContext = createContext({
	products: null,
	orderLabels: null,
	setOrderLabels: (() => undefined),
	setProducts: (() => undefined),
	currentTime: null,
	setCurrentTime: (() => undefined),
	loggedTime: null,
	setLoggedTime: (() => undefined),
	timeRemaining: 59,
	setTimeRemaining: (() => undefined),
	rows: 0,
	setRows: (() => undefined)
});

export const useAppContext = () => useContext(AppContext);

export const getLoggedtime = () => {
	const loggedTime = localStorage.getItem("loggedTime");
	return loggedTime || null;
}

export const AppProvider = ({ children }) => {
	const [products, setProducts] = useState(null);
	const [currentTime, setCurrentTime] = useState(null);
	const [timeRemaining, setTimeRemaining] = useState(59);
	const [loggedTime, setLoggedTime] = useState(getLoggedtime());
	const [rows, setRows] = useState(59);
	const [orderLabels, setOrderLabels] = useState(null);

	const value = useMemo(() => ({
		products, setProducts,
		currentTime, setCurrentTime,
		loggedTime, setLoggedTime,
		timeRemaining, setTimeRemaining,
		rows, setRows,
		orderLabels, setOrderLabels
	}), [products, currentTime, loggedTime, timeRemaining, rows, orderLabels]);

	return (
		<AppContext.Provider value={ value }>
			{ children }
		</AppContext.Provider>
	);
}
