import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Tesseract from 'tesseract.js';
import { Button, ButtonLight, ButtonPrimary } from '../../../components/ctas';
import Webcam from 'react-webcam';
import { PageHeader } from '../../../components/headers';
import { Field, Input, Label } from '../../../components/forms';
import { useGoogleSheet } from '../../../hooks/useGoogleSheet';
import { digitsOnly, formatDate, formatDateForInput, stripHtml, verifyField, verifyForm } from '../../../utils/formatting';
import { useAuth } from '../../../hooks/useAuth';
import { isMobileBP, timeDiff } from '../../../utils/global';
import { isMobile } from 'react-device-detect';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AspectImage } from "../../../components/assets";
import { toBeRequired } from '@testing-library/jest-dom/matchers';

const convertDigitToWord = (number) => {
    const digit = number ? parseInt(number) : -1;
    if (!digit || digit < 0 || digit > 9) return 'Digits';

    const digits = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    return digits[digit] || 'Digits';
}

const videoSizesForSerialNumber = {
	width: isMobileBP ? 300 : 4000,
	height: isMobileBP ? 168.75 : 1500
}

const videoSizesForCamera = {
	width: isMobileBP ? 300 : 800,
	height: isMobileBP ? 168.75 : 450
}

const removeUnderscore = (data) => {
    const newData = {};
    for (const key in data) {
        const newKey = key.split('_').map((word, index) => {
            if (index === 0) {
                return word;
            }
            return word[0].toUpperCase() + word.slice(1);
        }).join(' ');
        newData[newKey] = data[key];
    }
    return newData;
}


const compressedBase64 = (imgCur) => {
	return imgCur.split('').reduce((o, c) => {
		if (o[o.length - 2] === c && o[o.length - 1] < 35) o[o.length - 1]++;
		else o.push(c, 0);
		return o;
	}, []).map(_ => typeof _ === 'number' ? _.toString(36) : _).join('');
}

const decompressedBase64 = (imgCur) => {
	return compressedBase64(imgCur).split('').map((c,i,a)=>i%2?undefined:new Array(2+parseInt(a[i+1],36)).join(c)).join('');
}

const getTimeStamp = () => {
	const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}${month}${day}${hours}${minutes}${seconds}`;
}

const base64ToPng = (dataurl, filename) =>  {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:"image/png"});
}

const uploadImage = (image, fileName = "RVMP_QC", user = "NA") => {
    const prefix = "RVMP_QC";
    const name = `${ prefix }_${getTimeStamp()}_${ fileName }_${user}.png`;
    var data = new FormData()
    data.append('file', base64ToPng(image, name));
    data.append('fs', "rvmp")

    return new Promise((resolve, reject) => {
        fetch('https://rvmpapp.discreetaf.com?fs=true', {
            method: 'POST',
            body: data
        }).then(res => res.json()).then(data => resolve(data)).catch(err => reject("error"))
    })
}

const CameraForSerialNumber = ({ props, setImageToText, text, setValues, values, originalImage, setOriginalImage, facingMode }) => {
	const { currentUser } = useAuth();
	const webcamWrapperRef = useRef(null);
	const webcamRef = useRef(null);
	const [image, setImage] = useState(null);
	const [processedImage, setProcessedImage] = useState(null);
	const [videoDims, setVideoDims] = useState(null);
	const [videoConstraints, setVideoConstraints] = useState({
		width: videoSizesForSerialNumber.width,
		height: videoSizesForSerialNumber.height,
		minScreenshotWidth: videoSizesForSerialNumber.width,
		minScreenshotHeight: videoSizesForSerialNumber.height,
		facingMode: facingMode,
		screenshotQuality: 1,
		imageSmoothing: false,
		forceScreenshotSourceSize: false,
		aspectRatio: .25
	});

	const capture = useCallback((e) => {
		if (e) e.preventDefault();
		// { width: videoSizesForSerialNumber.width, height: videoSizesForSerialNumber.height }
		const imageSrc = webcamRef.current.getScreenshot();
		setImage(imageSrc);
		setOriginalImage(imageSrc);
		// setImageToText(imageSrc); // moved to processed in useEffect with "image"
	}, [webcamRef]);


	useEffect(() => {
		if (webcamWrapperRef?.current) {
			const width = webcamWrapperRef.current.offsetWidth;
			const height = webcamWrapperRef.current.offsetHeight;

			setVideoConstraints({
				...videoConstraints,
				width: videoSizesForSerialNumber.width,
				height: videoSizesForSerialNumber.height
			});

			setVideoDims({
				width: videoSizesForSerialNumber.width,
				height: videoSizesForSerialNumber.height
			});
		}
	}, [webcamWrapperRef?.current]);


	function applyFiltersToImage(imageURL, callBack) {
		// load the image url into an image object
		const image = new Image();
		image.src = imageURL;

		const canvas = document.createElement("canvas");
		const ctx = canvas.getContext('2d');
		image.onload = () => {
			canvas.width = image.width;
			canvas.height = image.height;

			// apply css filters here
			ctx.filter = 'brightness(.95) contrast(3) grayscale(1)';

			ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

			// turn canvas back into a base64 image URL
			callBack(canvas.toDataURL("image/png"));
		}
	}

	useEffect(() => {
		if (image) {
			applyFiltersToImage(image, (newImage) => {
				setProcessedImage(newImage)
				setImageToText(newImage)
			});
		}
	}, [image])


	return (videoSizesForSerialNumber?.width && videoSizesForSerialNumber?.height) && <>

		<div ref={webcamWrapperRef} className="tw-aspect-[var(--aspect)] tw-relative tw-overflow-clip tw-w-full tw-max-w-[var(--width)] tw-max-h-[var(--height)" style={{ '--height': `${videoSizesForSerialNumber.height}px`, '--width': `${videoSizesForSerialNumber.width}px`, '--aspect': `${ videoSizesForSerialNumber.width }/${ videoSizesForSerialNumber.height }` }}>
			{ videoDims?.height && videoConstraints.height && videoConstraints.width && <Webcam
				ref={ webcamRef }
				audio={ false }
				screenshotFormat="image/png"
				width={ videoSizesForSerialNumber.width }
				height={ videoSizesForSerialNumber.height }
				screenshotQuality={ 1 }
				imageSmoothing={ true }
				minScreenshotHeight={ videoConstraints.minScreenshotHeight }
				minScreenshotWidth={ videoConstraints.minScreenshotWidth }
				videoConstraints={ videoConstraints }
				forceScreenshotSourceSize={ false }
			>
				{({ getScreenshot }) => (
					<button
						className="tw-outline-none tw-border-n tw-border-none tw-shadow-none tw-bg-transparent tw-z-2 tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-w-full tw-h-full tw-object-fill"
						onClick={ capture }
					>
						{ text === '' ? 'Scan' : 'Scan Again'}
					</button>
				)}
			</Webcam> }
		</div>
		{/* { image && <>
			output: <br />
			<img src={image} className="!tw-max-w-[auto] !tw-w-[auto]" />
			<br />
			processedImage: <br />
			<img src={processedImage} className="!tw-max-w-[auto] !tw-w-[auto]" />
		</> } */}
	</>
}

const CameraForPhoto = ({ isEditing, values, setValues, facingMode }) => {
	const { currentUser } = useAuth();
	const webcamWrapperRef = useRef(null);
	const webcamRef = useRef(null);
	const [image, setImage] = useState(null);
	const [videoDims, setVideoDims] = useState(null);
	const [videoConstraints, setVideoConstraints] = useState({
		width: videoSizesForCamera.width,
		height: videoSizesForCamera.height,
		minScreenshotWidth: videoSizesForCamera.width,
		minScreenshotHeight: videoSizesForCamera.height,
		facingMode: facingMode,
		screenshotQuality: 1,
		imageSmoothing: false,
		forceScreenshotSourceSize: false,
		aspectRatio: .25
	});

	const capture = useCallback(() => {
		const imageSrc = webcamRef.current.getScreenshot();
		setImage(imageSrc);
	}, [webcamRef]);


	useEffect(() => {
		if (webcamWrapperRef?.current) {
			const width = webcamWrapperRef.current.offsetWidth;
			const height = webcamWrapperRef.current.offsetHeight;

			setVideoConstraints({
				...videoConstraints,
				width: videoSizesForCamera.width,
				height: videoSizesForCamera.height
			});

			setVideoDims({
				width: videoSizesForCamera.width,
				height: videoSizesForCamera.height
			});
		}
	}, [webcamWrapperRef?.current]);

	const retake = (e, retake = false) => {
		if (e) e.preventDefault();

		if (retake) {
			setEditPhoto(false);

		}

		setImage('');
		setValues({
			...values,
			"Photo": ""
		});

		verifyField(document.querySelector("#Photo"), "#formRef");
	}


	useEffect(() => {
		if (image && values?.Photo === '') {
			uploadImage(image, "Product_Photo", (currentUser?.userinfo?.name ? currentUser?.userinfo?.name.replaceAll(" ","_") : "NA") ).then((data) => {
				if (data?.src) {
					setValues({
						...values,
						"Photo": `https://rvmpapp.discreetaf.com/uploads/${data.src}`
					});
					verifyField(document.querySelector("#Photo"), "#formRef");
				}
			});
		}

		verifyField(document.querySelector("#Photo"), "#formRef");

	}, [image, values?.Photo])

	const [editPhoto, setEditPhoto] = useState(true);


	return (videoSizesForCamera?.width && videoSizesForCamera?.height) && <>
		<input type="hidden" required name="Photo" id="Photo" className="tw-text-black" value={ image || values?.Photo ? "Photo Taken" : "" } />
		{ isEditing && values?.Photo && editPhoto &&  <div className="tw-relative tw-overflow-clip">
				<img src={ values?.Photo } className="tw-w-full" />
				<button
					className=" tw-font-quaternary tw-font-quaternary-bold tw-uppercase tw-text-white tw-flex tw-items-center tw-justify-center tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-w-full tw-h-full tw-object-cover tw-shadow-none tw-outline-none tw-border-none tw-bg-transparent tw-rounded-none"
					onClick={ e => retake(e, true) }>
					<span>
						{ values?.Photo === '' ? 'Click to Take Photo' : 'Retake' }
					</span>
				</button>
		</div> }
		{ image ? <>
			<div className="tw-relative tw-overflow-clip">
				<img src={image} className="tw-w-full" />
				<button
					className=" tw-font-quaternary tw-font-quaternary-bold tw-uppercase tw-text-white tw-flex tw-items-center tw-justify-center tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-w-full tw-h-full tw-object-cover tw-shadow-none tw-outline-none tw-border-none tw-bg-transparent tw-rounded-none"
					onClick={ retake }>
					<span>
						{ values?.Photo === '' ? 'Click to Take Photo' : 'Retake' }
					</span>
				</button>
			</div>
		</> : <>
			<div ref={webcamWrapperRef} className={`${ values?.Photo && isEditing  ? '!tw-hidden tw-h-0 tw-w-0 tw-overflow-clip' : '' } tw-aspect-[var(--aspect)] tw-relative tw-overflow-clip tw-w-full tw-max-w-[var(--width)] tw-max-h-[var(--height)`} style={{ '--height': `${videoSizesForCamera.height}px`, '--width': `${videoSizesForCamera.width}px`, '--aspect': `${ videoSizesForCamera.width }/${ videoSizesForCamera.height }` }}>
				{ videoDims?.height && videoConstraints.height && videoConstraints.width && <Webcam
					ref={ webcamRef }
					audio={ false }
					screenshotFormat="image/png"
					width={ videoSizesForCamera.width }
					height={ videoSizesForCamera.height }
					screenshotQuality={ 1 }
					imageSmoothing={ true }
					minScreenshotHeight={ videoConstraints.minScreenshotHeight }
					minScreenshotWidth={ videoConstraints.minScreenshotWidth }
					videoConstraints={ videoConstraints }
					forceScreenshotSourceSize={ false }
				>
					{({ getScreenshot }) => (
						<button
						className=" tw-font-quaternary tw-font-quaternary-bold tw-uppercase tw-text-white tw-flex tw-items-center tw-justify-center tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-w-full tw-h-full tw-object-cover tw-shadow-none tw-outline-none tw-border-none tw-bg-transparent tw-rounded-none"
							onClick={ capture }
						>
							{ values?.Photo === '' ? 'Click to Take Photo' : 'Retake' }
						</button>
					)}
				</Webcam> }
			</div>
		</> }

	</>
}

const GetSerialNumberFromCamera = ({ isEditing, values, setValues, originalImage, setOriginalImage, facingMode }) => {
	const { currentUser } = useAuth();
	const { loadSheet, sheetRows } = useGoogleSheet({ spreadsheetId: process.env.REACT_APP_SERIAL_NUMBERS_SPREADSHEET_ID });
	const [isLoading, setIsLoading] = useState(false);
	const [imageToText, setImageToText] = useState('');
	const [image, setImage] = useState('');
	const [text, setText] = useState('');
	const [init, setInit] = useState('');
	const [serialNumbers, setSerialNumbers] = useState('');
	const [serialNumbersFormatted, setSerialNumbersFormatted] = useState('');
	const [progress, setProgress] = useState(0);

	const fixText = text => {
		const temp = text.replaceAll(" ","").replaceAll('O', '0').replaceAll('o', '0').replaceAll('\n', '').replaceAll('\t', '');
		const indexOf4L = temp.indexOf('4L1');
		return temp.substring(indexOf4L, indexOf4L + 16);
	}

	const filterImage = (image) => {
		if (!image) return;
	}

	const handleImageToText = image => {
		setIsLoading(true);
		Tesseract.recognize(image, 'eng', {
			logger: (m) => {
				if (m.status === 'recognizing text') {
					setProgress(parseInt(m.progress * 100));
				}
			},
		})
		.catch((err) => {
			console.error(err);
		})
		.then((result) => {
			setText(fixText(result.data.text));
			setIsLoading(false);
		});
	}


	const handleSubmit = () => {
		handleImageToText(image)
	};

	const handleChange = (e) => {
		setImage(URL.createObjectURL(e.target.files[0]))
	}

	const handleReset = (e) => {
		if (e) e.preventDefault();
		setImage('');
		setText('');
		setImageToText(null);
		window.scrollTo(0, 0);

		setValues({
			...values,
			"Serial_Photo": ""
		});
	}

	const handleTextChange = (e) => {
		const field = e.target;
		const value = field.value;
		const formatted = fixText(value);

		setText(formatted)
		field.value = formatted
	}


	useEffect(() => {
		if (imageToText) {
			setImage(imageToText);
			setText('');
			setTimeout(() => {
				handleImageToText(imageToText)
			}, 100);
		}
	}, [imageToText]);

	useEffect(() => {
		if (text && text.includes('4L1')) {
			setValues({
				...values,
				"Serial_Number": text
			});
		} else {
			if (!isEditing) {
				setValues({
					...values,
					"Serial_Number": ""
				});
			}
		}

	}, [text]);


	useEffect(() => {
		if (serialNumbers) {
			const temp = serialNumbers.reduce((acc, item) => {
				acc.push(item._rawData[0]);
				return acc
			}, []);
			setSerialNumbersFormatted(temp.sort((a, b) => a.localeCompare(b)));
		}
	}, [serialNumbers]);


	const updateSheetRows = async () => {
		if (init) {
			const results = await sheetRows || null;

			if (results) setSerialNumbers(results);

			return results;
		}
	}

	useEffect(() => {
		if (init) updateSheetRows();
	}, [init]);


	useLayoutEffect(() => {
		loadSheet(true).then((data) => {
			setInit(data)
		});
	}, []);

	useEffect(() => {
		if (originalImage && values?.Serial_Number?.includes('4L1') ) {
			uploadImage(originalImage, `Serial_Photo_${ values?.Serial_Number.replaceAll(" ","_") }`, (currentUser?.userinfo?.name ? currentUser?.userinfo?.name.replaceAll(" ","_") : "NA") ).then((data) => {
				if (data?.src) {
					setValues({
						...values,
						"Serial_Photo": `https://rvmpapp.discreetaf.com/uploads/${data.src}`
					});
				}
			});
		}
	}, [originalImage, values.Serial_Number]);

	return <>
		<div className="tw-space-y-15px">

			{/* { !isLoading && !text.includes('4L1') && <div>
				<Label tag="div" className="!tw-cursor-default tw-select-none">
					Scan Serial Number:
				</Label>
				<div className="[&_*]:tw-select-none">
					<CameraForSerialNumber facingMode={ facingMode } setImageToText={ setImageToText } text={ text } values={ values } setValues={ setValues } originalImage={ originalImage } setOriginalImage={ setOriginalImage } />
				</div>
			</div> } */}
			{/* <div>
				<img src="/images/IMG_4571.jpg" className="tw-w-full" />
			</div> */}
			<div>
				<div className="col-md-5 mx-auto h-100 d-flex flex-column justify-content-center">
					{/* {!isLoading && <>
						<h1 className="text-center py-5 mc-5">Image To Text</h1>
						<img src={image} className="tw-w-full tw-max-w-400px tw-max-h-400px tw-object-contain tw-object-left-top" />
					</>} */}
					{ isLoading && (
						<>
							<div className="tw-w-full tw-flex tw-flex-col">
								<progress className="tw-w-full tw-grayscale" value={ progress } max="100">
									{progress}%{' '}
								</progress>{' '}
								<p className="tw-text-center tw-py-0 tw-my-0 tw-w-full tw-flex tw-gap-5px tw-justify-center tw-items-center tw-font-quaternary-light tw-text-6 tw-tracking-widest tw-uppercase"><span>Converting:</span> <span className="tw-w-50px tw-text-right  tw-text-[.9em] tw-font-mono tw-tracking-normal">{progress} %</span></p>
							</div>
							{/* <img src={image} className="tw-w-full tw-max-w-400px tw-max-h-400px tw-object-contain tw-object-left-top" /> */}
						</>
					)}
					{!isLoading && !text && (
						<>
							{/* <input type="file" onChange={ handleChange } /> */}
							{/* <ButtonLight onClick={ handleSubmit } default className="tw-min-w-200px">
								{ !isLoading ? 'Convert' : 'Converting' }
							</ButtonLight> */}
						</>
					)}

					<div className="tw-flex tw-space-x-1px">
						{/* <input className="tw-text-black tw-font-mono tw-border-white" value={ text } onChange={ handleTextChange } /> */}

						<Field datalist={ serialNumbersFormatted } type="text" className="tw-flex-grow" required value={ (text?.includes('4L1') || values?.Serial_Number?.includes('4L1')) ? values?.Serial_Number || text  : "" } id={`serial_number`} data-column="ID" label='<span>Serial Number</span>' placeholder="Enter Serial Number" onChange={ handleTextChange } />

						{/* <ButtonLight onClick={ handleReset } default className="!tw-border-0px">
							Redo
						</ButtonLight> */}
					</div>

				</div>
			</div>
		</div>
	</>;
};


const CustomizePrefixButton = props => {
	const [toggleToCustom, setToggleToCustom] = useState(false);
	const [value, setValue] = useState(' ');

	const handleTogglereFixChange = (e) => {
		e.preventDefault();
		props.setSerialPrefix(null);
		setTimeout(() => {
			const field = document.querySelector(`#customizedPrefix${ props.type }`);
			if (field) field.focus();
		}, 150);
		setToggleToCustom(!toggleToCustom);
	}

	const handleCustomPrefix = e => {
		let value = e.target.value || "";

		props.setSerialPrefix(null);

		if (value.length > 1) {
			value = value.replaceAll(" ","").toUpperCase()
		} else {
			value = value.toUpperCase()
		}
		setValue(value || '');
	}

	const handleAddPrefix = e => {
		e.preventDefault();

		if (value.replaceAll(" ","").length > 4) {
			e.preventDefault();
			props.setSerialPrefix(value);
		} else {
			alert("Please enter a prefix value");
		}
	}

	useEffect(() => {
		const field = document.querySelector(`#customizedPrefix${ props.type }`);
		if (props?.resetCustom) {
			setToggleToCustom(false);
			props.setSerialPrefix(null);
			setValue('');
			props.setResetCustom(false);
			if (field) field.value = "";
		}
	}, [props?.resetCustom, props.value]);

	useLayoutEffect(() => {
		// setValue('');
		// document.querySelector(`#customizedPrefix${ props.type }`).value = "";
		// props.setSerialPrefix(null);
		// document.querySelector(`#customizedPrefix${ props.type }`).focus();
	}, []);

	return <>
		{ toggleToCustom ? <>
			<div className="tw-flex tw-w-full">
				<Input type="text" onInput={ handleCustomPrefix } id={`customizedPrefix${ props.type }`} className="tw-min-w-50px tw-flex-grow tw-min-h-[2.625rem] !tw-border-white focus:!tw-font-code focus:!tw-font-code-bold tw-font-code tw-font-code-normal"  minLength={ 5 } maxLength={ 15 } value={ value || "" } />
				<Button onClick={ handleAddPrefix } className="tw-bg-white -tw-ml-1px tw-text-black hover:tw-bg-primary tw-font-quaternary tw-font-quaternary-bold tw-uppercase tw-min-h-[2.625rem] tw-border-1px !tw-border-l-black tw-border-white hover:tw-text-black hover:tw-border-white tw-flex-shrink-0 tw-h-full tw-px-10px">
					Add
				</Button>
			</div>
		</> : <>
			<Button onClick={ e => handleTogglereFixChange(e) } className={`tw-bg-black tw-text-white tw-border-1px  tw-min-h-40px ${'tw-border-white' }`}>
				Custom
			</Button>
		</> }
	</>
}


const InputRadios = (props) => {
	const [value, setValue] = useState('');
	const [model, setModel] = useState(null);
	const [lastDigitsInputLength, setLastDigitsInputLength] = useState(6);
	const [lastDigitsInputValue, setLastDigitsInputValue] = useState('');
	const [serialPrefix, setSerialPrefix] = useState(null);
	const [resetCustom, setResetCustom] = useState(false);
	const [originalPreEditingSerial, setOriginalPreEditingSerial] = useState(null);
	const [count, setCount] = useState(0);

	const handleModelChange = (e, value) => {
		e.preventDefault();
		if (!value) return;
		props.setValues({
			...props.values,
			"Model": value || null
		});

		if (value === '2800i') {
			setLastDigitsInputLength(6);
		} else if (value === '5500i') {
			setLastDigitsInputLength(6);
		} else {
			setLastDigitsInputLength(6);
		}

		setModel(value);
		setSerialPrefix(null);
		setLastDigitsInputValue('');
	}

	const handlePrefixChange = (e, value) => {
		e.preventDefault();
		setResetCustom(true)
		setTimeout(() => {
			setSerialPrefix(null);
		}, 5);
		setTimeout(() => {
			setSerialPrefix(value);
		}, 10);
	}

	const handleLastDigitsInput = (e) => {
		const temp = e.target.value || "";
		setLastDigitsInputValue(temp);
	}

	useEffect(() => {
		if (serialPrefix && model && lastDigitsInputValue && (lastDigitsInputValue.length === lastDigitsInputLength)) {
			props.setValues({
				...props.values,
				"Serial_Number": `${serialPrefix}${lastDigitsInputValue}`
			});
		} else {
			props.setValues({
				...props.values,
				"Serial_Number": null
			});
		}
	}, [serialPrefix, model, lastDigitsInputValue]);

	useEffect(() => {
		if (serialPrefix) {
			const selector = document.querySelector("#LastDigitsInput");
			if (selector) selector.focus();
		}
	}, [serialPrefix]);

	useLayoutEffect(() => {
		if (props.isEditing && props.values?.Serial_Number && lastDigitsInputLength) {
			const lastDigits = props?.values?.Serial_Number?.slice(-lastDigitsInputLength);
			if (count === 0) {
				setOriginalPreEditingSerial(props.values?.Serial_Number);
				setCount(1);
			}

			setTimeout(() => {

				setLastDigitsInputValue(lastDigits);

				setModel(props?.values?.Model);
				setValue(props?.values?.Model || '');

				setSerialPrefix(props?.values?.Serial_Number.replace(lastDigits,''));
			}, 100);
		}
	}, [props.isEditing, props.values.Serial_Number, lastDigitsInputLength]);

	return <>

		<div className="tw-space-y-20px">
			<div>
				<Label default className="tw-min-h-30px tw-flex tw-items-end tw-py-2px tw-pointer-events-none">
					<span>
						Select Model:
					</span>
				</Label>
				<div className="tw-w-full tw-grid tw-grid-cols-3 -tw-space-x-1px [&_*]:tw-justify-center [&_*]:tw-items-center [&_*]:tw-flex">
					<Button onClick={ e => handleModelChange(e, '2800i')} className={`tw-bg-black tw-text-white tw-border-1px tw-px-20px tw-py-0px tw-min-h-40px ${ props.values?.Model === '2800i' ? ' || tw-invert tw-select-none tw-pointer-events-none tw-border-black' : 'tw-border-white' }`}>
						<AspectImage src={`/images/2800i.svg`} aspect="276/74" className="tw-w-full tw-max-h-20px" />
					</Button>
					<Button onClick={ e => handleModelChange(e, '4000i')} className={`tw-bg-black tw-text-white tw-border-1px tw-px-20px tw-py-0px tw-min-h-40px ${ props.values?.Model === '4000i' ? ' || tw-invert tw-select-none tw-pointer-events-none tw-border-black' : 'tw-border-white' }`}>
						<AspectImage src={`/images/4000i.svg`} aspect="285/74" className="tw-w-full tw-max-h-20px" />
					</Button>
					<Button onClick={ e => handleModelChange(e, '5500i')} className={`tw-bg-black tw-text-white tw-border-1px tw-px-20px tw-py-0px tw-min-h-40px ${ props.values?.Model === '5500i' ? ' || tw-invert tw-select-none tw-pointer-events-none tw-border-black' : 'tw-border-white' }`}>
						<AspectImage src={`/images/5500i.svg`} aspect="274/74" className="tw-w-full tw-max-h-20px" />
					</Button>
				</div>
			</div>
			{ model  && <>
				<div className="tw-flex tw-flex-col [&>*]:tw-w-full">
					<Label default className="tw-min-h-30px tw-flex tw-items-end tw-py-2px tw-pointer-events-none">
						<span>
							Select Prefix:
						</span>
					</Label>
					{ props.values?.Model === '2800i' && <>
						<div className="tw-w-full tw-grid tw-grid-cols-2 -tw-space-x-1px [&_*]:tw-justify-center [&_*]:tw-items-center [&_*]:tw-flex [&_*]:tw-font-code [&_*]:tw-font-code-bold">
							<Button onClick={ e => handlePrefixChange(e, '4L1R0AB2R')} className={`tw-bg-black tw-text-white tw-border-1px tw-min-h-40px ${ serialPrefix === '4L1R0AB2R' ? ' || tw-invert tw-select-none tw-pointer-events-none tw-border-black' : 'tw-border-white' }`}>
								4L1R0AB2R
							</Button>
							<CustomizePrefixButton type="2800i" setSerialPrefix={ setSerialPrefix } setValue={ setValue } serialPrefix={ serialPrefix } setResetCustom={ setResetCustom } resetCustom={ resetCustom } />
						</div>
					</> }
					{ props.values?.Model === '4000i' && <>
						<div className="tw-w-full tw-grid tw-grid-cols-4 -tw-space-x-1px [&_*]:tw-justify-center [&_*]:tw-items-center [&_*]:tw-flex [&_*]:tw-font-code [&_*]:tw-font-code-bold">
							<Button onClick={ e => handlePrefixChange(e, '4L1R0AB1P')} className={`tw-bg-black tw-text-white tw-border-1px tw-min-h-40px ${ serialPrefix === '4L1R0AB1P' ? ' || tw-invert tw-select-none tw-pointer-events-none tw-border-black' : 'tw-border-white' }`}>
								4L1R0AB1P
							</Button>
							<Button onClick={ e => handlePrefixChange(e, '4L1R0AB2P')} className={`tw-bg-black tw-text-white tw-border-1px tw-min-h-40px ${ serialPrefix === '4L1R0AB2P' ? ' || tw-invert tw-select-none tw-pointer-events-none tw-border-black' : 'tw-border-white' }`}>
								4L1R0AB2P
							</Button>
							<Button onClick={ e => handlePrefixChange(e, '4L1R0AB2R')} className={`tw-bg-black tw-text-white tw-border-1px tw-min-h-40px ${ serialPrefix === '4L1R0AB2R' ? ' || tw-invert tw-select-none tw-pointer-events-none tw-border-black' : 'tw-border-white' }`}>
								4L1R0AB2R
							</Button>
							<CustomizePrefixButton type="4000i" setSerialPrefix={ setSerialPrefix } setValue={ setValue } serialPrefix={ serialPrefix } setResetCustom={ setResetCustom } resetCustom={ resetCustom } />
						</div>
					</> }
					{ props.values?.Model === '5500i' && <>
						<div className="tw-w-full tw-grid tw-grid-cols-3 -tw-space-x-1px [&_*]:tw-justify-center [&_*]:tw-items-center [&_*]:tw-flex [&_*]:tw-font-code [&_*]:tw-font-code-bold">
							<Button onClick={ e => handlePrefixChange(e, '4L1R0AB1P')} className={`tw-bg-black tw-text-white tw-border-1p tw-min-h-40px ${ serialPrefix === '4L1R0AB1P' ? ' || tw-invert tw-select-none tw-pointer-events-none tw-border-black' : 'tw-border-white' }`}>
								4L1R0AB1P
							</Button>
							<Button onClick={ e => handlePrefixChange(e, '4L1R0AB2P')} className={`tw-bg-black tw-text-white tw-border-1px tw-min-h-40px ${ serialPrefix === '4L1R0AB2P' ? ' || tw-invert tw-select-none tw-pointer-events-none tw-border-black' : 'tw-border-white' }`}>
								4L1R0AB2P
							</Button>
							<CustomizePrefixButton type="5500i" setSerialPrefix={ setSerialPrefix } setValue={ setValue } serialPrefix={ serialPrefix } setResetCustom={ setResetCustom } resetCustom={ resetCustom } />
						</div>
					</> }
				</div>
			</> }
			{ serialPrefix && <>
				<div className="tw-flex tw-flex-col [&>*]:tw-w-full">
					<Label default htmlFor="LastDigitsInput" class="tw-min-h-30px tw-flex tw-items-end tw-py-2px">
						<span>
							Enter the Last { convertDigitToWord(lastDigitsInputLength) } of Serial Number
						</span>
					</Label>
					<Input type="tel" onInput={ handleLastDigitsInput } className="focus:tw-font-code focus:tw-font-code-normal" required id="LastDigitsInput" minLength={ lastDigitsInputLength } maxLength={ lastDigitsInputLength } value={ lastDigitsInputValue || "" } />
				</div>
			</> }

			{ props.isEditing && (props.values.Serial_Number !== originalPreEditingSerial)  && <>
				<div className="tw-border-1px tw-text-white tw-text-center tw-flex tw-flex-col tw-p-10px tw-rounded-3px">
					<span className="tw-font-quaternary tw-font-quaternary-normal tw-uppercase tw-text-[.6em] tw-tracking-[.2em]">
						Original Pre-edited Serial:
					</span>
					<span className="tw-font-code tw-font-code-bold"> { originalPreEditingSerial } </span>
				</div>
			</> }

			{ props.values.Serial_Number && <>
				<div className="tw-border-1px tw-text-white tw-text-center tw-flex tw-flex-col tw-p-10px tw-rounded-3px">
					<span className="tw-font-quaternary tw-font-quaternary-normal tw-uppercase tw-text-[.6em] tw-tracking-[.2em]">
						Output Serial:
					</span>
					<span className="tw-font-code tw-font-code-bold"> { props.values.Serial_Number } </span>
				</div>
			</> }

		</div>

	</>
}


const ToggleBtn = ({ keyId, label, values, setValues, id, name, required, column, handleUpdate }) => {
	const inputRef = useRef(null);
	const [selected, setSelected] = useState('');
	const [value, setValue] = useState('');

	const handleClicked = (e) => {
		if (e) e.preventDefault();
		const value = e.target.dataset.value;
		setValue(value || '');
		setSelected(value);
		setValues({
			...values,
			[keyId]: value
		})

		verifyField(inputRef?.current, "#formRef");

		if (handleUpdate && inputRef?.current) {
			handleUpdate(inputRef?.current, true);
		}
	}


	const handleChange = (e, el = false) => {
		if (el === false) {
			setValues({
				...values,
				[e.target.dataset.column.replaceAll(" ","_")]: e.target.value
			})

			verifyField(e.target, "#formRef");

		} else {
			setValues({
				...values,
				[e.dataset.column.replaceAll(" ","_")]: ""
			})

			verifyField(e, "#formRef");
		}
	}

	useEffect(() => {
		if (selected && selected !== "No") {
			const el = document.querySelector(`#${id}_details`);
			if (el) {
				el.value = "";
				handleChange(el, true);
			}
		}
		verifyForm(document.querySelector("#formRef"));
	}, [selected])

	useLayoutEffect(() => {
		if (values) {
			const temp = values[keyId];
			setSelected(temp);
			setValue(temp || '');
		}
	}, [values])

	return <>
		<div>
			<input ref={ inputRef } type="hidden" required={ required || null } id={ id } name={ name || id } className="tw-text-black" data-column={ column } value={ value || "" } />
			<div className="tw-flex tw-space-x-10px tw-items-center">
				<Label for={ id } className="tw-w-full tw-pb-3p tw-flex-grow">
					{ label }
				</Label>
				<div className="tw-w-250px tw-rounded-6px tw-flex tw-overflow-clip [&_button]:tw-w-full [&_button]:tw-font-quaternary-black [&_button]:tw-uppercase [&_button]:tw-px-5px [&_button]:tw-justify-center [&_button]:tw-items-center [&_button]:tw-min-w-50px [&_button]:tw-min-h-40px [&_button]:tw-border-none tw-border-1px tw-border-white tw-divide-x-1px tw-divide-white">
					<div className="tw-flex-grow tw-flex-shrink-0">
						<Button data-value="Yes" className={selected === "Yes" ? `tw-bg-primary tw-text-black tw-cursor-default tw-pointer-events-none` : `tw-bg-secondary hover:tw-bg-white hover:tw-text-black tw-transition-all tw-duration-200`} onClick={ handleClicked }>
							Yes
						</Button>
					</div>
					<div>
						<Button data-value="N/A" className={`!tw-min-w-25px ${ selected === "N/A" ? ` tw-bg-primary tw-text-black tw-cursor-default tw-pointer-events-none` : `tw-bg-secondary hover:tw-bg-white hover:tw-text-black tw-transition-all tw-duration-200`}`} onClick={ handleClicked }>
							<span className="tw-font-quaternary-light tw-text-[.7em]">N/A</span>
						</Button>
					</div>
					<div className="tw-flex-grow tw-flex-shrink-0">
						<Button data-value="No" className={selected === "No" ? `tw-bg-primary tw-text-black tw-cursor-default tw-pointer-events-none` : `tw-bg-secondary hover:tw-bg-white hover:tw-text-black tw-transition-all tw-duration-200`} onClick={ handleClicked }>
							No
						</Button>
					</div>
				</div>
			</div>
			{ selected === "No" && <>
				<div>
					<Field required id={`${id}_details`} data-column={`${column} Details`} onChange={ handleChange }  datalist={["Demo here", "another reason", "foobar"]} label="Add Details" placeholder={`Reason for '${ stripHtml(label) }' being 'NO'`} />
				</div>
			</>}
		</div>
	</>
}

// const GetSerialNumber = ({ limit, prefix, limitValue, setDigitsNeeded, digitsNeeded, isEditing, values, setValues }) => {
// 	const fieldHolder = useRef(null);
// 	const { loadSheet, sheetRows } = useGoogleSheet({ spreadsheetId: process.env.REACT_APP_SERIAL_NUMBERS_SPREADSHEET_ID });
// 	const [init, setInit] = useState('');
// 	const [serialNumbers, setSerialNumbers] = useState('');
// 	const [serialNumbersFormatted, setSerialNumbersFormatted] = useState('');
// 	const [progress, setProgress] = useState(0);


// 	useEffect(() => {
// 		if (serialNumbers) {
// 			const temp = serialNumbers.reduce((acc, item) => {
// 				acc.push(item._rawData[0]);
// 				return acc
// 			}, []);
// 			setSerialNumbersFormatted(temp.sort((a, b) => a.localeCompare(b)));
// 		}
// 	}, [serialNumbers]);


// 	const updateSheetRows = async () => {
// 		if (init) {
// 			const results = await sheetRows || null;

// 			if (results) setSerialNumbers(results);

// 			return results;
// 		}
// 	}

// 	useEffect(() => {
// 		if (init) updateSheetRows();
// 	}, [init]);


// 	useLayoutEffect(() => {
// 		loadSheet(true).then((data) => {
// 			setInit(data)
// 		});
// 	}, []);

// 	const handleLastSix = (e) => {
// 		const target = e.target;
// 		const value = target.value;
// 		const formatted = `${prefix}${ value }`;
// 		const container = fieldHolder?.current;

// 		setValues({
// 			...values,
// 			"Serial_Number": formatted
// 		});

// 		if (value?.length > (digitsNeeded - 1) || limit !== limitValue) {
// 			verifyField(target, "#formRef");
// 			container.classList.remove("!tw-max-h-0px");
// 			container.querySelector("input").value = formatted;
// 			verifyField(container.querySelector("input"), "#formRef");
// 		} else {
// 			container.classList.add("!tw-max-h-0px");
// 		}
// 	}

// 	const updateSerialNumber = (e) => {
// 		const target = e.target;
// 		const value = target.value;

// 		setValues({
// 			...values,
// 			"Serial_Number": value
// 		});

// 		verifyField(target, "#formRef");
// 	}

// 	useLayoutEffect(() => {
// 		const container = fieldHolder?.current;
// 		if (isEditing && container) {
// 			container.classList.remove("!tw-max-h-0px");
// 			document.querySelector("#Serial_Last_6").parentNode.classList.add("tw-hidden");
// 		}
// 	}, [isEditing, fieldHolder]);

// 	return <>
// 		<div className="tw-flex tw-flex-col tw-space-y-10px">
// 			<Field type="tel" maxLength={ digitsNeeded } minLength={ limit === limitValue ? digitsNeeded : null } className="tw-my-10px tw-flex-grow" id="Serial_Last_6" label={`<span>Last ${ limit === limitValue ? ((digitsNeeded === 6) ? 'Six' : 5) : '' } of Serial</span>`}  placeholder={ limit === limitValue ? `Last ${ digitsNeeded } of Serial` : 'Last Digits of Serials' } onChange={ handleLastSix } />
// 			<div ref={fieldHolder} className="tw-max-h-60px !tw-max-h-0px tw-overflow-clip tw-transition-all tw-duration-300">
// 				<Field datalist={ serialNumbersFormatted } type="text" className="tw-flex-grow" minLength={ limit } maxLength="18" required value={ values?.Serial_Number || "" } id={`serial_number`} data-column="ID" label='<span>Serial Number</span>' placeholder="Enter Serial Number" onChange={ updateSerialNumber } />
// 			</div>
// 		</div>
// 	</>
// }

const QCForm = ({ limit, setLimit, isEditing, values, setValues, handleUpdate, facingMode }) => {
	const { currentUser } = useAuth();
	const [rows, setRows] = useState([]);
	const [serialPreviouslyExists, setSerialPreviouslyExists] = useState(false);
	const [pastSerialNumbers, setPastSerialNumbers] = useState([]);
	const [notPassed, setNotPassed] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();
	const { loadSheet, sheetRows, setRow, addRow, saveRow } = useGoogleSheet({ spreadsheetId: process.env.REACT_APP_QC_SPREADSHEET_ID });
	const formRef = useRef(null);
	const [limitValue, setLimitValue] = useState(15);
	const [digitsNeeded, setDigitsNeeded] = useState(6);
	const [prefix, setPrefix] = useState('4L1R0AB2P');
	const [legacyMode, setLegacyMode] = useState(false);


	useEffect(() => {
		setLimit(limitValue);
	}, [limitValue]);


	const [originalImage, setOriginalImage] = useState('');

	const createRow = (values) => {
		return new Promise((resolve, reject) => {
			if (values.Serial_Number !== "") {

				addRow(values);

				resolve(`"${values.Serial_Number}" ${ isEditing ? "Updated" : "Created" }`);

			} else {
				reject("No ID");
			}
		});
	}


	const handleSave = (e, includePrint = false)  => {
		if (e) e.preventDefault();
		const target = e.target;
		target.classList.add("_loading")


		if (isEditing) {
			const tempEntries = {
				...values,
				"Updated On": new Date().toISOString(),
				"Updated By": `${currentUser.userinfo ? currentUser.userinfo?.name : 'Unknown'}`,
			}
			const entries = removeUnderscore(tempEntries);
			const sheets = loadSheet(true).then((sheets) => {
				let row = sheets?.find(row => row._rowNumber === values.index);

				const promises = Object.keys(entries).map(key => setRow(row, key, entries[key]));
				Promise.all(promises).then(() => saveRow(row));
			});

		} else {
			const tempEntries = {
				...values,
				"Modified Date": new Date().toISOString(),
				"Modified By": `${currentUser.userinfo ? currentUser.userinfo?.name : 'Unknown'}`,
			}
			const entries = removeUnderscore(tempEntries);
			createRow(entries).then((data) => {
				console.log(`Row Created`, data);
				// success message here
			})
		}


		if (target) {
			setTimeout(() => {
				target.classList.remove("_loading")
				target.classList.add("_success");

				if (includePrint === true) {
					// window.open(`/dashboard/qc/${ values.Serial_Number  }`, "_blank");
					window.location.href = `/dashboard/qc/${ values.Serial_Number  }`;
				}

				setTimeout(() => {
					target.classList.remove("_success")

					if (includePrint === false) {
						window.scrollTo(0, 0)
						window.location.href = `/dashboard/qc/completed`;
					}

					document.querySelector("#Serial_Last_6").value = "";
					document.querySelector("#serial_number").value = "";

					setValues({
						Model: null,
						Serial_Number: "",
						Start_Time: new Date().toISOString(),
						Exhaust_Plug_Installed: "",
						Conduit_Properly_Installed: "",
						// Fuel_Hoses_Properly_Routed: "",
						// V2_Fuel_Pump_Replaced_Installed: "",
						Firmware_Across_All_Device_At_Current_Version: "",
						Fuel_Pump_Module_Flashed: "",
						All_Connectors_Securely_Attached: "",
						Correct_Amount_Of_Oil_Installed: "",
						// Second_Hole_Drilled_And_Screen_Installed: "",
						Isolators_Installed: "",
						All_Hardware_Reinstalled: "",
						All_Wires_And_Hoses_Secured_With_Zip_Ties: "",
						Tested_For_Operation_And_Output: "",
						Remote_Harness_Checked_For_Operation: "",
						// Units_Properly_Stacked: "",
						// Units_Properly_Wrapped_For_Shipping: "",
						// Units_Cleared_To_Ship: "",
						Photo: "",
						Serial_Photo: "",
						Notes: "",
						PO: ""
					});

				}, 1000);
			}, 750);
		}
	}

	const handleFieldUpdate = e => {

		const field = e.target;
		const value = field.value;

		setValues({
			...values,
			[field.id]: value
		});

		verifyField(field, "#formRef");
	}

	const handleDateField = e => {
		const field = e?.target;

		if (field) {
			const value = field.value;
			const isDate = field.getAttribute("format") === "date";
			const isNumber = field.getAttribute("format") === "number";
			const formatted = (value && value !== "") && isDate ? formatDateForInput(digitsOnly(value)).mmddyyyy : (value && value !== "") && isNumber ? digitsOnly(value) : value;

			field.value = formatted;

			setValues({
				...values,
				"Ship_Date": formatted
			});

			verifyField(field, "#formRef");
		}
	}

	const doesSerialExist = (serial, model) =>  {
		if (!pastSerialNumbers) return;
		if (pastSerialNumbers && pastSerialNumbers.length < 1) return

		if (pastSerialNumbers && pastSerialNumbers.length > 0) {
			const temp =  pastSerialNumbers.reduce((acc, obj) => {
				if (obj.serial === serial.toLowerCase() && obj.model === model) {
					return true;
				}
				return acc;
			}, false);
			return temp;
		}
  	}


	const handleLast6OfSerial = e => {
		const temp = e?.target;
		const tempValue = temp.value && temp.value.length > 0 ? digitsOnly(temp.value) : "";
		const value = `{$prefix}${ tempValue }`;
		const target = document.querySelector("#serial_number");
		target.value = value;

		setValues({
			...values,
			"Serial_Number": value
		});

		temp.value = tempValue;

		setSerialPreviouslyExists(doesSerialExist(value, values?.Model || "4000i"))

		verifyField(target, "#formRef");
	}


	const makeSerialObject = async (obj) => {
		const temp = [];
		rows.forEach((row, index) => {
			temp.push({
				"serial": row.get('Serial Number').toLowerCase(),
				"model": row.get('Model').toLowerCase()
			});
		});

		return temp;
	}

	useEffect(() => {
		if (values?.Serial_Number) {
			setSerialPreviouslyExists(doesSerialExist(values.Serial_Number, values?.Model || "4000i"))
		}
	}, [values?.Serial_Number]);

	useEffect(() => {
		if (rows && rows.length > 0) {
			makeSerialObject(rows).then((data) => {
				setPastSerialNumbers(data);
			})
		}
	}, [rows]);

	useEffect(() => {
		const keys = Object.keys(values);
		const valuesArray = Object.values(values);
		const noExists = valuesArray.includes("Not");


		setNotPassed(noExists);

	}, [values]);

	useLayoutEffect(() => {
		loadSheet(true).then((sheets) => {
			setRows(sheets);
		});
	}, []);

	useEffect(() => {
		if (notPassed == true) {
			setValues({
				...values,
				"PO": "Not Passed",
				"OEM": "Not Passed"
			});
		}
	}, [notPassed]);

	return <>
		<form id="formRef" ref={ formRef } onSubmit={ handleSave } className="invalid validate || tw-flex tw-flex-col tw-w-full tw-gap-7px tw-space-y-20px md:tw-space-y-30px">
			<div>
				<div className="tw-pb-15px">
					<Field className="tw-flex-grow" id={`Model`} data-column="Model">
						<InputRadios isEditing={ isEditing } setLimitValue={ setLimitValue } setPrefix={ setPrefix } digitsNeeded={ digitsNeeded } setDigitsNeeded={ setDigitsNeeded }  prefix={ prefix } setPrefix={ setPrefix }setValues={ setValues } values={ values } />
					</Field>
				</div>
				<div>
					<div className="tw-pb-20px md:tw-pb-30px">
						{/* <GetSerialNumber limitValue={ limitValue } setLimitValue={ setLimitValue } digitsNeeded={ digitsNeeded } setDigitsNeeded={ setDigitsNeeded }  prefix={ prefix } setPrefix={ setPrefix } isEditing={ isEditing } limit={ limit } limitValue={ limitValue} facingMode={ facingMode } values={ values } setValues={ setValues }  originalImage={ originalImage } setOriginalImage={ setOriginalImage } /> */}

						{/* { values?.Serial_Number?.length < limit && <div className="tw-w-full tw-flex tw-justify-end">
							<Button className="tw-min-h-30px tw-px-0px tw-border-transparent" onClick={ e => {
								e.preventDefault();
								setLimit(12);
								setLegacyMode(true);
							}}>
								{ legacyMode === true ? 'Legacy Mode' : 'Is legacy?' }
							</Button>
						</div> } */}

						{/* <GetSerialNumberFromCamera isEditing={ isEditing } facingMode={ facingMode } values={ values } setValues={ setValues }  originalImage={ originalImage } setOriginalImage={ setOriginalImage } /> */}
						{ values?.Serial_Photo ? <>
							{/*
							<a href={ values.Serial_Photo } target="_blank" className="tw-text-dynamic tw-w-100px tw-relative tw-border-1px tw-mt-5px tw-h-50px tw-inline-flex" >
								<img src={ values.Serial_Photo } className="tw-w-full tw-h-full tw-block tw-object-cover tw-object-center" />
							</a>
							*/}
						</> : <>
							{/*
							{ values?.Serial_Number && <div className="tw-text-dynamic tw-w-100px tw-pt-[1em] tw-h-50px tw-inline-flex tw-items-center" style={{ "--min-font-size": 1, "--max-font-size": 5 }}>
								<LoaderInline />
							</div> }
							*/}
						</> }
						{/* <Field type="tel" maxLength="6" minLength="6" className="tw-my-10px tw-flex-grow" id="Serial_Last_6" label='<span>Last Six of Serial</span>'  placeholder="Last 6 of Serial" onChange={ handleLast6OfSerial } /> */}
					</div>
					{ serialPreviouslyExists && !isEditing && <div className="tw-bg-red-500 tw-rounded-6px tw-overflow-clip tw-text-center tw-px-20px tw-py-15px tw-text-white tw-border-2px tw-border-white tw-uppercase tw-font-quaternary-bold">
						THIS SERIAL NUMBER EXISTS ALREADY!
					</div> }

					{ ((values?.Serial_Number) || isEditing) && <>

						<div className="[&_>_*:nth-child(odd)]:tw-bg-black/20 [&>*+*]:tw- tw-divide-y-1px tw-divide-white/5  -tw-mx-20px md:-tw-mx-30px">
							{
								Object.keys(values).map((key, index) => {
									const keyWithoutUnderscore = key.replaceAll("_", " ");
									return (key !== "Fuel_Hoses_Properly_Routed" && key !== "Model" && key !== "Serial_Number" && key !== "Start_Time" && key !== "Serial_Photo" && key !== "Photo" && key !== "Notes" && key !== "PO" && key !== "Ship_Date" && key !== "OEM" && !key.includes("_Details")) && <>
										{ (values?.Model === "4000i" && key == "Fuel_Pump_Module_Flashed") || (isEditing && key == "index") || (isEditing && key == "Modified_Date") || (isEditing && key == "Modified_By") || (isEditing && key == "Units_Properly_Stacked") || (isEditing && key == "Units_Properly_Wrapped_For_Shipping") || (isEditing && key == "Units_Cleared_To_Ship") ? <></> : <>
											<div key={`${ key }_index`} className="tw-py-15px tw-p-20px md:tw-px-30px">
												<ToggleBtn handleUpdate={ handleUpdate } setValues={ setValues } values={ values } required type="text" className="tw-flex-grow" initValue={``} value={``} keyId={ key } id={ key.toLowerCase() } name={ key.toLowerCase() } column={ keyWithoutUnderscore } label={`<span>${ keyWithoutUnderscore }</span>`}  />
												{/* <Field type="text" className="tw-flex-grow" required initValue={``} value={``} id={ key.toLowerCase() } data-column={ keyWithoutUnderscore } label={`<span>${ keyWithoutUnderscore }</span>`} placeholder={`${ key }`} onChange={ handleUpdate } /> */}
											</div>
										</> }
									</>
								})
							}
						</div>
						<div className="tw-pt-20px md:tw-pt-30px">
							<Field value={ values?.OEM || "" } type="input" className="tw-flex-grow" id="OEM" data-column="OEM" label='<span>OEM <span class=" tw-font-quaternary-lite tw-text-[.5em] tw-tracking-widest">Optional</span></span>'  placeholder="Enter OEM Name" onChange={ handleFieldUpdate } />
						</div>
						<div className="tw-pt-20px md:tw-pt-30px">
							<Field value={ values?.PO || "" } type="input" className="tw-flex-grow [&_input]:!tw-font-code [&_input]:!tw-font-code-bold" id="PO" data-column="PO" label='<span>Purchase Order <span class=" tw-font-quaternary-lite tw-text-[.5em] tw-tracking-widest">Optional</span></span>'  placeholder="Enter PO for this unit" onChange={ handleFieldUpdate } />
						</div>
						<div className="tw-pt-20px md:tw-pt-30px">
							<Field value={ values?.Ship_Date || "" } type="tel" format="date" maxLength="10" minLength="10" className="tw-flex-grow" id="Ship_Date" data-column="Ship Date" label='<span>Ship Date <span class=" tw-font-quaternary-lite tw-text-[.5em] tw-tracking-widest">Optional</span></span>'  placeholder="MM/DD/YYYY"  onChange={ handleDateField } />
						</div>
						<div className="tw-pt-20px md:tw-pt-30px">
							<Field value={ values?.Notes || "" } type="textarea" className="tw-flex-grow" id="Notes" data-column="Notes" label='<span>Notes</span>'  placeholder="Add any notes (if needed)" onChange={ handleFieldUpdate } />
						</div>
					</> }
				</div>
			</div>
			{ ((values?.Serial_Number) || isEditing) && <>

				<div className="tw-flex tw-flex-col md:tw-flex-row md:tw-justify-between tw-gap-15px">
					<ButtonPrimary className="c-submit-button tw-w-full md:tw-w-auto tw-transition-all tw-duration-300" default onClick={ handleSave }>
						<span className="tw-inline-flex tw-text-4 tw-px-20px tw-py-5px tw-min-h-30px tw-min-w-120p tw-w-full tw-text-center tw-justify-center">
							<span>
								{ isEditing ? "Update QC" : "Complete QC" }
							</span>
						</span>
					</ButtonPrimary>
					<ButtonLight className="md:-tw-order-1 c-submit-button tw-text-center tw-justify-center tw-w-full md:tw-w-auto tw-transition-all tw-duration-300  tw-items-center" onClick={ e => handleSave(e, true) }>
						<span className="tw-inline-flex  tw-m-auto  tw-justify-center  tw-uppercase tw-text-4 tw-px-10px tw-py-5px tw-min-h-20px tw-font-quaternary-bold tw-items-center tw-w-full tw-text-center tw-justify-center">
							<span className="tw-text-[.75rem]">
								{ isEditing ? "Update" : "Save" } &amp; Print
							</span>
						</span>
					</ButtonLight>


					{/* <Link  to={`/dashboard/qc/print-all`} state={{"filterValue" : row.get('Serial Number') }} className="tw-outline-none tw-border-1px tw-rounded-none tw-shadow-none tw-inline-flex  || tw-bg-white tw-text-secondary hover:tw-bg-primary hover:tw-text-black tw-border-black  || md:-tw-order-1 c-submit-button tw-text-center tw-justify-center tw-w-full md:tw-w-auto tw-transition-all tw-duration-300  tw-items-center">
						<span class="tw-pointer-events-none">
							<span class="tw-inline-flex  tw-m-auto  tw-justify-center  tw-uppercase tw-text-4 tw-px-10px tw-py-5px tw-min-h-20px tw-font-quaternary-bold tw-items-center tw-w-full tw-text-center tw-justify-center">
								<span class="tw-text-[.75rem]">
									{ isEditing ? "Update" : "Save" } &amp; Print
								</span>
							</span>
						</span>
					</Link> */}


				</div>
			</> }
		</form>
	</>
}

const QCList = (props) => {
	const { loadSheet, sheetRows, setRow, addRow, saveRow } = useGoogleSheet({ spreadsheetId: process.env.REACT_APP_QC_SPREADSHEET_ID });
	const [rows, setRows] = useState([]);
	const [rowsDisplay, setRowsDisplay] = useState([]);
	const [dates, setDates] = useState();
	const [countsWithDate, setCountsWithDate] = useState();
	const location = useLocation();


	const LoadRows = (cache = false) => {
		// caching attempt:
		// if (cache) {
		// 	setRows(JSON.parse(localStorage.getItem('qc_items')));
		// } else {
		// 	const sheets = loadSheet(true).then((sheets) => {
		// 		localStorage.setItem('sync_date_qc', new Date().toISOString());
		// 		setRows(sheets);
		// 		localStorage.setItem('qc_items', sheets)
		// 	});
		// }

		const sheets = loadSheet(true).then((sheets) => {
			setRows(sheets);
		});
	}


	useLayoutEffect(() => {
		// caching attempt:
		// const now = new Date().toISOString();
		// if (localStorage.getItem('sync_date_qc')) {
		// 	if ( (timeDiff(localStorage.getItem('sync_date_qc'), now, true) < 15) && localStorage.getItem('qc_items') ) {
		// 		LoadRows(true);
		// 	} else {
		// 		LoadRows();
		// 	}
		// } else {
		// 	LoadRows();
		// }
	}, []);

	useEffect(() => {
		if (props.filteredRows) {
			setRows(props.filteredRows);
		}
	}, [props.filteredRows])


	useEffect(() => {
		if (rows) {
			const unique = [...new Set(rows.map(item => formatDate(item.get('Modified Date'))))];
			setDates(unique.sort((a, b) => new Date(b) - new Date(a)));

			const counts = unique.map(date => {
				return {
					count: rows.filter(row => formatDate(row.get('Modified Date')) === date).length,
					reject: rows.filter(row => ((row.get('PO')) === "Not Passed") && (formatDate(row.get('Modified Date')) === date)).length
				}
			});

			const countsWithDateTemp = unique.map((date, index) => {
				return {
					date: date,
					count: counts[index].count,
					reject: counts[index].reject || 0
				}
			});

			setCountsWithDate(countsWithDateTemp);

			const sorted_obj = rows.sort((a, b) => new Date(b.get('Modified Date')) - new Date(a.get('Modified Date')));

			setRowsDisplay(sorted_obj);
		}
	}, [rows]);


	return rowsDisplay && countsWithDate && <>
		<ul className="tw-flex tw-flex-col tw-p-0px tw-divide-y-1px tw-divide-white">
			{ rowsDisplay.map( (row, index) => {
				return <li className="tw-flex tw-flex-col md:tw-flex-row tw-gap-10px tw-leading-none tw-py-10px" key={`result_${ index }`}>
					<span className="tw-flex tw-flex-col md:tw-flex-row tw-gap-10px tw-leading-none tw-flex-grow">
						<span>
							<Link to={`/dashboard/qc/edit`} className="tw-text-primary hover:tw-text-white tw-transition-all tw-duration-200 tw-mt-10px tw-block md:tw-inline-block md:tw-mt-0px" state={{
								"index": row._rowNumber,
								"Serial_Number": row.get('Serial Number'),
								"Model": row.get('Model'),
								"Modified_Date": row.get('Modified Date'),
								"Modified_By": row.get('Modified By'),
								"Start_Time": row.get('Start Time'),
								"Exhaust_Plug_Installed": row.get('Exhaust Plug Installed'),
								"Exhaust_Plug_Installed_Details": row.get('Exhaust Plug Installed Details'),
								"Conduit_Properly_Installed": row.get('Conduit Properly Installed'),
								"Conduit_Properly_Installed_Details": row.get('Conduit Properly Installed Details'),
								// "Fuel_Hoses_Properly_Routed": row.get('Fuel Hoses Properly Routed'),
								"Fuel_Hoses_Properly_Routed_Details": row.get('Fuel Hoses Properly Routed Details'),
								// "V2_Fuel_Pump_Replaced_Installed": row.get('V2 Fuel Pump Replaced Installed'),
								// "V2_Fuel_Pump_Replaced_Installed_Details": row.get('V2 Fuel Pump Replaced Installed Details'),
								"Firmware_Across_All_Device_At_Current_Version": row.get('Firmware Across All Device At Current Version'),
								"Firmware_Across_All_Device_At_Current_Version_Details": row.get('Firmware Across All Device At Current Version Details'),
								"Fuel_Pump_Module_Flashed": row.get('Fuel Pump Module Flashed'),
								"Fuel_Pump_Module_Flashed_Details": row.get('Fuel Pump Module Flashed Details'),
								"All_Connectors_Securely_Attached": row.get('All Connectors Securely Attached'),
								"All_Connectors_Securely_Attached_Details": row.get('All Connectors Securely Attached Details'),
								"Correct_Amount_Of_Oil_Installed": row.get('Correct Amount Of Oil Installed'),
								"Correct_Amount_Of_Oil_Installed_Details": row.get('Correct Amount Of Oil Installed Details'),
								// "Second_Hole_Drilled_And_Screen_Installed": row.get('Second Hole Drilled And Screen Installed'),
								// "Second_Hole_Drilled_And_Screen_Installed_Details": row.get('Second Hole Drilled And Screen Installed Details'),
								"Isolators_Installed": row.get('Isolators Installed'),
								"Isolators_Installed_Details": row.get('Isolators Installed Details'),
								"All_Hardware_Reinstalled": row.get('All Hardware Reinstalled'),
								"All_Hardware_Reinstalled_Details": row.get('All Hardware Reinstalled Details'),
								"All_Wires_And_Hoses_Secured_With_Zip_Ties": row.get('All Wires And Hoses Secured With Zip Ties'),
								"All_Wires_And_Hoses_Secured_With_Zip_Ties_Details": row.get('All Wires And Hoses Secured With Zip Ties Details'),
								"Tested_For_Operation_And_Output": row.get('Tested For Operation And Output'),
								"Tested_For_Operation_And_Output_Details": row.get('Tested For Operation And Output Details'),
								"Remote_Harness_Checked_For_Operation": row.get('Remote Harness Checked For Operation'),
								"Remote_Harness_Checked_For_Operation_Details": row.get('Remote Harness Checked For Operation Details'),
								"Units_Properly_Stacked": row.get('Units Properly Stacked'),
								"Units_Properly_Stacked_Details": row.get('Units Properly Stacked Details'),
								"Units_Properly_Wrapped_For_Shipping": row.get('Units Properly Wrapped For Shipping'),
								"Units_Properly_Wrapped_For_Shipping_Details": row.get('Units Properly Wrapped For Shipping Details'),
								"Units_Cleared_To_Ship": row.get('Units Cleared To Ship'),
								"Units_Cleared_To_Ship_Details": row.get('Units Cleared To Ship Details'),
								"Photo": row.get('Photo'),
								"Serial_Photo": row.get('Serial Photo'),
								"PO": row.get('PO'),
								"Notes": row.get('Notes'),
								"OEM": row.get('OEM'),
								"Ship_Date": row.get('Ship Date'),
							}}>
								<span>
									Edit
								</span>
							</Link>
						</span>
						<span>
							<span className="tw-inline-flex md:tw-hidden">
								Created On:&nbsp;
							</span>
							<span>
								{ formatDate(row.get('Modified Date')) }
							</span>
						</span>
						<span className="tw-flex-grow tw-justify-start">
							<span className="tw-inline-flex md:tw-hidden">
								Model #:&nbsp;
							</span>
							<span className="tw-space-x-5px">
								{ row.get('Model') }<span className="tw-hidden md:tw-inline-block tw-font-code tw-font-code-bold hover:tw-text-primary tw-cursor-pointer" onClick={ e => props.updateFilterValue2(e, row.get('Serial Number'))}><span className="tw-text-white tw-font-quaternary-normal">:</span> { row.get('Serial Number') }</span>
							</span>
						</span>
						<span className="md:tw-hidden">
							<span className="tw-inline-flex md:tw-hidden">
								Serial #:&nbsp;
							</span>
							<span className="tw-font-quaternary-bold hover:tw-text-primary tw-cursor-pointer tw-inline-block" onClick={ e => props.updateFilterValue2(e, row.get('Serial Number'))}>
								{ row.get('Serial Number') }
							</span>
						</span>
						{ row.get('PO') === "Not Passed" ? <></> : <>
							<span className="tw-inline-block md:tw-hidden">
								OEM: { row.get('OEM') }
							</span>
						</> }
						<span>
							{ row.get('PO') === "Not Passed" ? "" : "PO: " } <b className="tw-font-quaternary-bold hover:tw-text-primary tw-cursor-pointer tw-inline-block" onClick={ e => props.updateFilterValue2(e, row.get('PO'))}><span className="tw-font-code tw-font-bold">{ row.get('PO') }</span> <span className={`tw-hidden tw-font-code tw-font-code-bold md:tw-inline-block ${row.get('PO') === "Not Passed" ? "!tw-hidden" : ""}`}>({ row.get('OEM') } { row.get('Ship Date') !== "" && ` - ${row.get('Ship Date')}` })</span></b>
						</span>
						<span className="tw-block md:tw-hidden">
							Ship Date: { row.get('Ship Date') !== "" ? row.get('Ship Date') : '--' }
						</span>
					</span>
					<span className="tw-ml-auto md:tw-ml-0px">
						<Link  to={`/dashboard/qc/print-all`} state={{"filterValue" : row.get('Serial Number') }} className="tw-outline-none tw-border-1px tw-rounded-none tw-shadow-none tw-inline-flex  || tw-leading-none tw-items-center tw-justify-center tw-transition-all tw-duration-200 tw-uppercase tw-font-quaternary tw-font-quaternary-bold tw-tracking-tight tw-select-none || tw-bg-primary tw-text-black hover:tw-bg-secondary hover:tw-text-primary tw-border-primary hover:tw-border-secondary">
							<span className="tw-pointer-events-none || tw-px-20px tw-py-7px">Print</span>
						</Link>
					</span>
				</li>
			}) }
		</ul>

		<div className="tw-w-full tw-mt-60px" id="Breakdown">
			<div className="tw-flex tw-w-full tw-gap-5px tw-text-center tw-font-quaternary-black tw-uppercase tw-justify-between tw-items-center tw-py-10px tw-px-20px tw-bg-black/10 tw-mb-10px tw-rounded-6px">
				<b>Date</b> <b>Count</b>
			</div>
			{ countsWithDate.map((item, index) => {
				return <div key={`date_${ index }`} className="tw-flex tw-w-full tw-gap-5px tw-justify-between tw-items-center tw-py-10px tw-px-20px tw-bg-black/10 tw-mb-10px tw-rounded-6px">
					<span>{ item.date }</span> <span>{ item.count - item.reject } ({ item.reject })</span>
				</div>
			})}
		</div>
	</>
}

const QC = (props) => {
	const { currentUser } = useAuth();
	const [facingMode, setFacingMode] = useState({ exact: "environment" });
	const[isReady, setIsReady] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const location = useLocation();
	const [limit, setLimit] = useState(15);

	// const [facingMode, setFacingMode] = useState("user");

	const [values, setValues] = useState({
		Model: null,
		Serial_Number: "",
		Start_Time: new Date().toISOString(),
		Exhaust_Plug_Installed: "",
		Conduit_Properly_Installed: "",
		// Fuel_Hoses_Properly_Routed: "",
		// V2_Fuel_Pump_Replaced_Installed: "",
		Firmware_Across_All_Device_At_Current_Version: "",
		Fuel_Pump_Module_Flashed: "",
		All_Connectors_Securely_Attached: "",
		Correct_Amount_Of_Oil_Installed: "",
		// Second_Hole_Drilled_And_Screen_Installed: "",
		Isolators_Installed: "",
		All_Hardware_Reinstalled: "",
		All_Wires_And_Hoses_Secured_With_Zip_Ties: "",
		Tested_For_Operation_And_Output: "",
		Remote_Harness_Checked_For_Operation: "",
		// Units_Properly_Stacked: "",
		// Units_Properly_Wrapped_For_Shipping: "",
		// Units_Cleared_To_Ship: "",
		Photo: "",
		Serial_Photo: "",
		Notes: "",
		PO: ""
	});

	const handleUpdate = (e, isElement = false) => {
		const field = isElement ? e : e.target;
		const value = field.value;


		if (isElement === false) {
			setValues({
				...values,
				[field.id]: value
			});
		}

		verifyField(field, "#formRef");
	}

	useEffect(() => {
	}, [values]);

	useLayoutEffect(() => {
		if (isMobile) {
			setFacingMode({ exact: "environment" } );
		} else {
			setFacingMode("user");
		}
		setTimeout(() => {
			setIsReady(true);
		}, 800);
	}, []);

	useEffect(() => {
		if (location?.state) {
			setTimeout(() => {
				const temp = location?.state;
				setValues(temp)
				setIsEditing(true)
			}, 500);
		}
	}, [location?.state]);

	return <>
		<div className="tw-flex tw-flex-col">
			<div className="tw-w-full tw-space-y-30px tw-pb-60px">
				<PageHeader className="tw-text-primary tw-w-full tw-text-center tw-leading-[.9]">
					<span className="tw-font-primary tw-font-primary-light">RVMP</span>{` `} QC
				</PageHeader>
				{ isReady && <section>
					<div className="tw-w-full xtw-min-h-screen tw-space-y-60px">
						<div className="tw-w-full tw-max-w-500px tw-p-20px md:tw-p-30px tw-rounded-12px tw-bg-[#292826] tw-border-1px tw-border-white/10 tw-shadow-sm tw-mx-auto">
							<QCForm limit={ limit } setLimit={ setLimit } isEditing={ isEditing } facingMode={ facingMode } values={ values } setValues={ setValues } handleUpdate={ handleUpdate } />
						</div>
					</div>
				</section> }
			</div>
			{/* <div>

			</div> */}
			<div className="tw-w-full tw-py-20px tw-flex tw-px-40px tw-justify-center">
				<ButtonPrimary default to="/dashboard/qc/completed" className="tw-justify-center tw-text-center">
					View Completed
				</ButtonPrimary>
			</div>
		</div>
	</>
}

export const QCCompletedList = (props) => {
	const { loadSheet, sheetRows, setRow, addRow, saveRow } = useGoogleSheet({ spreadsheetId: process.env.REACT_APP_QC_SPREADSHEET_ID });
	const [filteredResults, setFilteredResults] = useState([]);
	const [filteredResults2, setFilteredResults2] = useState([]);
	const [filteredResults4, setFilteredResults4] = useState([]);
	const [filteredResults5, setFilteredResults5] = useState([]);
	const [rows, setRows] = useState([]);
	const [filterValue, setFilterValue] = useState("");
	const [showSerialOutput, setShowSerialOutput] = useState(false);
	const serialsContainer2 = useRef(null);
	const serialsContainer5 = useRef(null);
	const serialsContainer4 = useRef(null);

	useLayoutEffect(() => {
		const sheets = loadSheet(true).then((sheets) => {
			// const sheetRow = sheets?.find(row => row.get('Serial Number') === ID);
			setFilteredResults(sheets);
			setFilteredResults2(filterData(sheets, "2800i"));
			setFilteredResults4(filterData(sheets, "4000i"));
			setFilteredResults5(filterData(sheets, "5500i"));
			setTimeout(() => {
				setRows(sheets);
			}, 50);
		});
	}, []);

	useEffect(() => {
		if (rows) {
			setFilteredResults(filterData("kevinmackrules"))
		}
	}, [rows]);

	useLayoutEffect(() => {
		if (props.serials) {
			setShowSerialOutput(true)
		}
	}, [props.serials]);

	const filterData = (searchTerm, model = null) => { // partial matches
		if (!searchTerm || searchTerm === "" || searchTerm === " ") {
			return rows;
		}

		let search = searchTerm.toString().toLowerCase();
		// let acc = [];


		return rows.reduce((acc, curr)  => {
			if (searchTerm === "kevinmackrules") {
				if (curr.get('Hide') !== 'TRUE') {
					acc.push(curr);
				}
			} else {
				if (curr.get('Serial Number')?.toLowerCase().includes(search)) {
					if (curr.get('Hide') !== 'TRUE') {
						if (model) {
							if (model === curr.get('Model')) acc.push(curr);
						} else {
							acc.push(curr);
						}
					}
				}

				if (curr.get('PO')?.toLowerCase().includes(search)) {
					if (curr.get('Hide') !== 'TRUE') {
						if (model) {
							if (model === curr.get('Model')) acc.push(curr);
						} else {
							acc.push(curr);
						}
					}
				}
				// if (curr.get('Serial Number').toLowerCase().includes(search)) {
				// 	if (curr.get('Hide') !== 'TRUE') {
				// 		acc.push(curr);
				// 	}
				// }
			}
			return acc;
		}, []);
	}

	const handleFilter = (e) => {
		const temp = e.target.value;
		if (!temp) return;
		setFilteredResults(filterData(temp));
		setFilteredResults2(filterData(temp, "2800i"));
		setFilteredResults4(filterData(temp, "4000i"));
		setFilteredResults5(filterData(temp, "5500i"));

		setFilterValue(temp);
	}

	const copySerials = (e, type = 5) => {
		// 5 = 5550i
		// 4 = 4000i
		if (e) e.preventDefault();

		const element = type === 5 ? serialsContainer5.current : (type === 2) ? serialsContainer2.current : serialsContainer4.current;

		if (serialsContainer5 && serialsContainer4 && serialsContainer2) {
			navigator.clipboard.writeText(element.textContent).then(() => {
				alert('Text copied');
			}).catch((err) => console.error(err.name, err.message));

		} else {
			alert("Oops something went wrong, tell Kevin Mack.")
		}

	}

	const updateFilterValue2 = (e, filteredValue) => {
		if (e) e.preventDefault();
		document.querySelector("#searchElement").value = filteredValue;


		setFilteredResults([]);
		setFilteredResults2([]);
		setFilteredResults4([]);
		setFilteredResults5([]);
		setTimeout(() => {
			setFilteredResults(filterData(filteredValue));
			setFilteredResults2(filterData(filteredValue, "2800i"));
			setFilteredResults4(filterData(filteredValue, "4000i"));
			setFilteredResults5(filterData(filteredValue, "5500i"));
		}, 100);

		setFilterValue(filteredValue);
 	}

	return <>
		<ButtonPrimary default to="/dashboard/qc" className="tw-w-full tw-mb-30px tw-py-10px">
			Back to QC
		</ButtonPrimary>

		<div className="tw-border-b-1px tw-border-white/20 tw-pb-20px md:tw-pb-30px tw-mb-30px">
			<form onSubmit={ e => e.preventDefault() }>
				<div className="tw-flex tw-space-x-15px">
					<Label htmlFor="searchElement" className="tw-items-center tw-flex-shrink-0 md:tw-min-w-60px" default>
						<span className="tw-flex tw-flex-col tw-w-full">
							Filter
							<span className="tw-text-[.7em] tw-font-quaternary-bold tw-text-white/60 tw-hidden md:tw-block">
								Results: { filteredResults.length }
							</span>
						</span>
					</Label>
					<div className="tw-flex-grow [&_input]:!tw-font-code [&_input]:!tw-font-code-bold">
						<Input className="tw-w-full tw-font-code tw-font-code-bold [&_input]:!tw-font-code [&_input]:!tw-font-code-bold" type="search" id="searchElement" list="product-list" value={ filterValue || "" }  placeholder="Search for PO or Serial #" onChange={ handleFilter } />
					</div>
				</div>
				<div className="tw-block md:tw-hidden tw-text-right">
					<span className="tw-text-[.7em] tw-font-quaternary-bold tw-text-white/60">
						Results: { filteredResults.length }
					</span>
				</div>
				<button type="submit" className="tw-hidden" onSubmit={ e => e.preventDefault() } onClick={ e => e.preventDefault() }>
					Search
				</button>
			</form>
		</div>
		<div className="tw-flex tw-w-full tw-justify-end tw-pb-30px">
			<a href="#Breakdown" className="tw-min-h-30px tw-inline-flex tw-items-center tw-text-white hover:tw-text-primary">
				Breakdowns
			</a>
		</div>
		{ filteredResults.length > 0 && filterValue && <div className={`${ filteredResults.length > 0 && filterValue ? '' : showSerialOutput ? '' : ' !tw-max-h-0 !tw-opacity-0' } ${showSerialOutput ? '' : 'tw-max-h-150px'  }  tw-opacity-100 tw-w-full tw-transition-all tw-delay-300 tw-duration-700 tw-overflow-clip`}>
			<div className="tw-pb-30px tw-mb-20px tw-border-b tw-border-b-white/20 tw-w-full tw-flex tw-gap-5px tw-flex-wrap md:tw-justify-end">
				<Link default className="tw-w-full md:tw-w-auto tw-outline-none tw-border-1px tw-rounded-none tw-shadow-none tw-inline-flex || tw-leading-none tw-items-center tw-justify-center tw-transition-all tw-duration-200 tw-uppercase tw-font-quaternary tw-font-quaternary-bold tw-tracking-tight tw-select-none || tw-bg-white tw-text-secondary hover:tw-bg-primary hover:tw-text-black tw-border-black || tw-min-w-250px tw-py-10px" to={`/dashboard/qc/print-all`} state={{"filterValue" : filterValue }}>
					<span className="tw-pointer-events-none">
						Print  { filteredResults.length > 1 ? ` All ${filteredResults.length} Results` : ' Result' }
					</span>
				</Link>


				{ showSerialOutput && <>
					<ButtonPrimary default className={`md:tw-ml-auto md:tw-min-w-250px tw-py-10px tw-w-full md:tw-w-auto${ filteredResults2 < 1 ? ' !tw-pointer-events-none !tw-grayscale !tw-opacity-20' : '' }`} onClick={ e => copySerials(e, 2) }>
						<span className="tw-pointer-events-none tw-tran tw-normal-case">
							  { filteredResults2.length > 1 ? ` COPY ALL ${filteredResults2.length} 2800i's` : ' COPY 2800i' }
						</span>
					</ButtonPrimary>
				</> }

				{ showSerialOutput && <>
					<ButtonPrimary default className={`md:tw-ml-20px md:tw-min-w-250px tw-py-10px tw-w-full md:tw-w-auto${ filteredResults5 < 1 ? ' !tw-pointer-events-none !tw-grayscale !tw-opacity-20' : '' }`} onClick={ e => copySerials(e, 5) }>
						<span className="tw-pointer-events-none tw-tran tw-normal-case">
							  { filteredResults5.length > 1 ? ` COPY ALL ${filteredResults5.length} 5500i's` : ' COPY 5000i' }
						</span>
					</ButtonPrimary>
				</> }

				{ showSerialOutput && <>
					<ButtonPrimary default className={`md:tw-ml-20px md:tw-min-w-250px tw-py-10px tw-w-full md:tw-w-auto${ filteredResults4 < 1 ? ' !tw-pointer-events-none !tw-grayscale !tw-opacity-20' : '' }`} onClick={ e => copySerials(e, 4) }>
						<span className="tw-pointer-events-none tw-tran tw-normal-case">
							COPY  { filteredResults4.length > 1 ? ` ALL ${filteredResults4.length} 4000i's` : ' 4000i' }
						</span>
					</ButtonPrimary>
				</> }

				{ showSerialOutput && <div className="tw-w-full tw-flex tw-flex-col tw-my-30px">
					<div ref={ serialsContainer2 } className="tw-hidden">
						{ filteredResults2.map((row, index) => <div key={`showSerialOutput_${ index }`}>{ row.get('Serial Number') }{`\n`}</div> )}
					</div>
					<div ref={ serialsContainer5 } className="tw-hidden">
						{ filteredResults5.map((row, index) => <div key={`showSerialOutput_${ index }`}>{ row.get('Serial Number') }{`\n`}</div> )}
					</div>
					<div ref={ serialsContainer4 } className="tw-hidden">
						{ filteredResults4.map((row, index) => <div key={`showSerialOutput_${ index }`}>{ row.get('Serial Number') }{`\n`}</div> )}
					</div>
					<table className="tw-block md:tw-table tw-space-y-40px md:tw-space-y-0px  tw-w-full tw-font-mono tw-text-white tw-leading-[1] [&_td]:tw-py-10p [&_td]:tw-px-5p [&_th]:tw-text-left [&_th]:tw-pb-10p">
						<tr className='tw-border-b-2px tw-mb-5px tw-hidden md:tw-table-row'>
							<th>
								PO
							</th>
							<th>
								Ship Date
							</th>
							<th>
								Created By
							</th>
							<th>
								Created On
							</th>
							<th>
								Updated By
							</th>
							<th>
								Updated On
							</th>
							<th className="!tw-text-right">
								Serial Number
							</th>
						</tr>
						{ filteredResults.map((row, index) => {
							return <tr key={`showSerialOutput_${ index }`} className='md:hover:tw-bg-white/10 [&_td>span]:tw-py-5px md:[&_td]:tw-py-0px tw-table [&_span]:tw-table-cell md:[&_span]:tw-inline-block [&_td]:tw-table-row md:[&_td]:tw-table-cell md:tw-table-row'>
								<td>
									<span className="md:!tw-hidden">
										PO: &nbsp;
									</span>
									<span className='hover:tw-text-primary tw-cursor-pointer tw-font-code tw-font-code-bold' onClick={ e =>  updateFilterValue2(e, row.get('PO')) }>{ row.get('PO') }</span>
								</td>
								<td>
									<span className="md:!tw-hidden">
										Ship Date: &nbsp;
									</span>
									<span>{ row.get('Ship Date') }</span>
								</td>
								<td>
									<span className="md:!tw-hidden">
										Created By: &nbsp;
									</span>
									<span>{ row.get('Modified By') }</span>
								</td>
								<td>
									<span className="md:!tw-hidden">
										Created On: &nbsp;
									</span>
									<span>{ row.get('Modified Date') ? formatDate(row.get('Modified Date')) : "--" }</span>
								</td>
								<td>
									<span className="md:!tw-hidden">
										Updated By: &nbsp;
									</span>
									<span>{ row.get('Updated By') || "--" }</span>
								</td>
								<td>
									<span className="md:!tw-hidden">
										Updated On: &nbsp;
									</span>
									<span>{ row.get('Updated On') ? formatDate(row.get('Updated On')) : "--" }</span>
								</td>
								<td className="md:tw-min-w-200px md:tw-text-right hover:tw-text-primary tw-cursor-pointer" onClick={ e => updateFilterValue2(e, row.get('Serial Number')) }>
									<span className="md:!tw-hidden">
										Serial #: &nbsp;
									</span>
									{ row.get('Serial Number') }
								</td>
							</tr>
						})}
					</table>
		</div> }

				{/* <ButtonPrimary target="_blank" default href={`/dashboard/qc/${ row.get('Serial Number') }`}></ButtonPrimary> */}
				{/*  review Edit "state" */}
			</div>
		</div> }
		<QCList filteredRows={ filteredResults } updateFilterValue2={ updateFilterValue2 } />
	</>
}

export default QC;
