import { ButtonPrimary } from "../../../components/ctas";
import { PageHeader } from "../../../components/headers";
import { useAppContext } from "../../../contexts/app";

const ButtonLink = (props) => {
	return <>
		<div>
			<ButtonPrimary {...props } className="tw-w-full tw-aspect-square tw-items-center tw-justify-center" default>
				{ props.children }
			</ButtonPrimary>
		</div>
	</>
}

export default function Home() {
	const { products } = useAppContext();

	return <>
		<div className="tw-space-y-30px tw-pb-60px">
			<PageHeader className="tw-w-full tw-text-center">
				Welcome
			</PageHeader>
			<section className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-20px md:tw-gap-40px tw-max-w-700px tw-mx-auto md:tw-px-40px">
				<ButtonLink to="/dashboard/inventory">
					Inventory Tracker
				</ButtonLink>
				<ButtonLink to="/dashboard/weight-dims">
					Size &amp; Weight
				</ButtonLink>
				<ButtonLink to="/dashboard/qc">
					QC
				</ButtonLink>
				<ButtonLink to="/dashboard/qc/completed">
					QC Completed
				</ButtonLink>
				<ButtonLink to="/dashboard/qc/completed/serials">
					QC Serials
				</ButtonLink>

				<ButtonLink to="/dashboard/order-labels">
					Labels
				</ButtonLink>
			</section>
		</div>
	</>
}
