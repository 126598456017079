import { Field, Input, Label } from "../../../components/forms";
import { ButtonPrimary } from "../../../components/ctas";
import { AspectImage } from "../../../components/assets";
import { ArticleHeader, PageHeader } from "../../../components/headers";
import { useEffect, useLayoutEffect, useState } from "react";
import { getProducts } from "../../../utils/api";
import { useAppContext } from "../../../contexts/app";
import { useGoogleSheet } from "../../../hooks/useGoogleSheet";
import { digitsOnly, formatDate, formatNumber } from "../../../utils/formatting";
import { useAuth } from "../../../hooks/useAuth";
import { getPropertyFromId, stringToArray, timeDiff, wrapText, wrapTextArray } from "../../../utils/global";

export default function Inventory() {
	const { currentUser } = useAuth();
	const [filteredProducts, setFilteredProducts] = useState(null);
	const [productsAlphabetically, setProductsAlphabetically] = useState(null);
	const { products, setProducts } = useAppContext();

	const { loadSheet, sheetRows, setRow, addRow, saveRow } = useGoogleSheet({ spreadsheetId: process.env.REACT_APP_INVENTORY_SPREADSHEET_ID });


	const sortProductsAlphabetically = (products) => {
		let productsArray = [];
		for (let key in products) {
			productsArray.push(products[key]);
		}
		productsArray.sort((a, b) => {
			if (a.title < b.title) {
				return -1;
			} else if (a.title > b.title) {
				return 1;
			} else {
				return 0;
			}
		});
		return productsArray;
	}

	/*
	const filterDataFullMatch = (dataObj, searchTerm) => {
		let search = searchTerm.toLowerCase();
		return dataObj.reduce((acc, curr) => {
			let title = curr.title.toLowerCase();
			let body = curr.body_html.toLowerCase();
			let handle = curr.handle.toLowerCase();
			if (title.includes(search) || body.includes(search) || handle.includes(search)) {
				acc.push(curr);
			}
			return acc;
		}, []);
	}
	*/

	const filterData = (dataObj, searchTerm) => { // partial matches
		let search = searchTerm.toLowerCase();
		return dataObj.reduce((acc, curr) => {
			let title = curr.title.toLowerCase();
			let body = curr.body_html.toLowerCase();
			let handle = curr.handle.toLowerCase();
			if (title.includes(search) || body.includes(search) || handle.includes(search)) {
				acc.push(curr);
			}
			return acc;
		}, []);
	}


	useEffect(() => {
		if (filteredProducts) setProductsAlphabetically(sortProductsAlphabetically(filteredProducts))
	}, [filteredProducts]);

	useEffect(() => {
		if (products) setFilteredProducts(products)
	}, [products]);

	const InitProducts = (cache = false) => {
		if (cache) {
			setProducts(JSON.parse(localStorage.getItem('products')));
			loadSheet();
		} else {
			localStorage.setItem('sync_date', new Date().toISOString());
			getProducts().then(res => {
				if (res){
					localStorage.setItem('products', JSON.stringify(res.products))
					setProducts(res.products);
					loadSheet();
				}
			});
		}
	}

	useLayoutEffect(() => {
		const now = new Date().toISOString();
		if (localStorage.getItem('sync_date')) {
			if ( (timeDiff(localStorage.getItem('sync_date'), now, true) < 15) && localStorage.getItem('products') ) {
				InitProducts(true);
			} else {
				InitProducts();
			}
		} else {
			InitProducts();
		}
	}, []);


	const createRow = (product) => {
		return new Promise((resolve, reject) => {
			const productName = getPropertyFromId(products, Number(product), 'title');
			addRow({
				'ID': product,
				'Product Name': `${ productName }`,
				'Modified Date': `${ new Date().toISOString() }`,
				'Modified By': `${ currentUser.userinfo ? currentUser.userinfo?.name : 'Unknown' }`
			});
			resolve(`"${productName}" (ID: ${ product })`);
		});
	}


	const handleUpdate = (event, save = false, target = null) => {
		const field = save ? event : event.target;
		const product = field.parentNode.parentNode.parentNode.dataset?.product || field.parentNode.dataset?.product || null;
		const columnName = field.parentNode.parentNode.parentNode.dataset?.column || field.parentNode.dataset?.column || null;


		if (product && columnName) {
			let row = sheetRows?.find( row => row.get('ID') === product );


			if (!row) {
				createRow(product).then((res) => {
					console.log("New Product Created in Spreadsheet", res)
				});
			}


			const value = (columnName === "Notes") ? (field.value ? field.value : '') : (field.value ? digitsOnly(field.value) : '');
			field.value = (columnName === "Notes") ? value ? value : '' : (value ? formatNumber(value) : '');

			if (save) {
				setRow(row, 'Modified Date', new Date().toISOString());
				setRow(row, 'Modified By', currentUser.userinfo ? currentUser.userinfo?.name : 'Unknown');
				setRow(row, columnName, value);
				saveRow(row);

				if (target) {
					setTimeout(() => {
						target.classList.remove("_loading")
					}, 750);
				}
			}
		}
	}

	const handleSave = (e) => {
		e.preventDefault();
		const target = e.target;
		target.classList.add("_loading")
		document.querySelectorAll(`[data-fieldset="${ e.target.dataset.product }"] input[data-product], [data-fieldset="${ e.target.dataset.product }"] textarea[data-product]`).forEach(field => {
			handleUpdate(field, true, target);
		});
	}

	return <>
		<div className="tw-space-y-30px tw-pb-60px">
			<PageHeader className="tw-text-primary tw-w-full tw-text-center tw-leading-[.9]">
				<span className="tw-font-primary tw-font-primary-light">RVMP</span>{` `}Inventory Tracker
			</PageHeader>
			<section>
				<form className="tw-w-full tw-min-h-screen" onSubmit={ e => { e.preventDefault() }}>
					{ productsAlphabetically && <div className="tw-space-y-40px tw-w-full tw-max-w-500px tw-p-20px md:tw-p-30px tw-rounded-12px tw-bg-[#292826] tw-border-1px tw-border-white/10 tw-shadow-sm tw-mx-auto">
						<div className="tw-border-b-1px tw-border-white/20 tw-pb-20px md:tw-pb-30px tw-mb-30px">
							<form onSubmit={ e => e.preventDefault() }>
								<div className="tw-flex tw-space-x-15px">
									<Label htmlFor="searchElement" className="tw-items-center tw-flex-shrink-0 md:tw-min-w-60px" default>
										<span className="tw-flex tw-flex-col tw-w-full">
											Filter
											<span className="tw-text-[.7em] tw-font-quaternary-bold tw-text-white/60 tw-hidden md:tw-block">Results: { productsAlphabetically.length }</span>
										</span>
									</Label>
									<div className="tw-flex-grow">
										<Input className="tw-w-full" type="search" id="searchElement" list="product-list"  placeholder="Search for product..." onChange={(e) => setFilteredProducts(filterData(products, e.target.value))} />
									</div>
								</div>
								<div className="tw-block md:tw-hidden tw-text-right">
									<span className="tw-text-[.7em] tw-font-quaternary-bold tw-text-white/60">Results: { productsAlphabetically.length }</span>
								</div>
								<button type="submit" className="tw-hidden" onSubmit={ e => e.preventDefault() } onClick={ e => e.preventDefault() }>
									Search
								</button>
							</form>
						</div>
						{ productsAlphabetically.length > 0 ? productsAlphabetically.map( (product, index) => {

							{/* const sheetRow = sheetRows?.[index]; is this safe enough???*/}
							const sheetRow = sheetRows?.find(row => row.get('ID') === product.id.toString());
							const initialCountColumbus = sheetRow?.get('Columbus') || 0;
							const initialCountColumbusRefurb = sheetRow?.get('Columbus Refurb') || 0;
							const initialCountElkhart = sheetRow?.get('Elkhart') || 0;
							const initialCountElkhartRefurb = sheetRow?.get('Elkhart Refurb') || 0;
							const initialCount = Number(initialCountColumbus) + Number(initialCountElkhart);
							const editDate = sheetRow?.get('Modified Date') ? formatDate(sheetRow?.get('Modified Date')) : formatDate(new Date().toISOString());
							const editor = sheetRow?.get('Modified By') || "N/A";

							const notes = sheetRow?.get('Notes') || null;
							const initialNotes = initialCount && initialCount > 0 ? `${ editDate }: ${ editor }\nTotal: ${ formatNumber(initialCount) } (C: ${ initialCountColumbus ? formatNumber(initialCountColumbus) : 0 } | E: ${ initialCountElkhart ? formatNumber(initialCountElkhart) : 0 })${ notes ? "\n\n" + notes : "" }\n\n` : "";


							return <div key={ product.id + "_" + index } className="tw-flex tw-flex-col tw-w-full tw-gap-7px tw-border-b-1px tw-border-b-white/20 tw-pb-40px">
								<div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-15px tw-pb-10px tw-pt-10px tw-sticky tw-top-0px tw-z-2 tw-bg-[#292826]">
									<div className="tw-flex tw-items-start tw-w-1/2 md:tw-w-100px tw-flex-shrink-0">
										<label for={`count_field_${product.id}_${index}`} className="tw-cursor-pointer tw-select-none tw-w-full tw-border-1px tw-bg-secondary tw-text-white tw-rounded-6px tw-overflow-clip tw-border-black">
											<AspectImage src={ product.images[0]?.src } alt={ product.title } className="tw-w-full" aspect="16/9" />
										</label>
									</div>
									<div className="tw-flex-grow tw-flex tw-flex-col">
										<ArticleHeader className="tw-pt-5px tw-text-primary [&>span_span]:tw-normal-case [&_sup]:tw-text-[.5em] [&_sup]:tw-top-[-.75em] [&_sup]:tw-font-quaternary-lite">
											<span dangerouslySetInnerHTML={{ __html: wrapTextArray(wrapText(stringToArray(product.title)[0],"®","sup"),["2200i","3330i","4500ies","4500ies","5500ies","4000i","5500i","9000i","LifePO4","LiFePO4"],"span") }}></span>
										</ArticleHeader>
										<span className="tw-w-full tw-font-quaternary-light tw-uppercase tw-text-[.75em]">
											SKU/Model #: <span className="tw-font-mono tw-text-[.9em]">{ product.variants[0]?.sku || "N/A" }</span>
										</span>
										<div className="tw-mt-auto tw-pb-5px tw-font-quaternary-light tw-uppercase tw-text-[.75em]">
											<ul className="tw-flex tw-gap-5px tw-leading-[1.1]">
												<li className="tw-flex tw-flex-row tw-space-x-[.5ch] tw-text-white/60">
													<span>Total Current:</span> <b className="tw-font-quaternary-bold">{ initialCount === 0 ? 'N/A' : formatNumber(initialCount) }</b>
												</li>
												{/*
												<li className="tw-flex tw-flex-col">
													<span>Elkhart Current:</span>
													<b className="tw-font-quaternary-bold">
														{ initialCountElkhart === 0 ? 'N/A' :  formatNumber(initialCountElkhart) }
														{ initialCountElkhartRefurb > 0 && <>
															{` `}({initialCountElkhartRefurb})
														</> }
													</b>
												</li>
												<li className="tw-flex tw-flex-col">
													<span>Columbus Current:</span>
													<b className="tw-font-quaternary-bold">
														{ initialCountColumbus === 0 ? 'N/A' : formatNumber(initialCountColumbus) }
														{ initialCountElkhartRefurb > 0 && <>
															{` `}({initialCountElkhartRefurb})
														</> }
													</b>
												</li>
												*/}
											</ul>
										</div>
									</div>
								</div>
								<div className="tw-flex tw-flex-col"  data-fieldset={ product.id }>
									<div className="tw-w-full tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-x-5px tw-gap-y-10px tw-grid-rows-2 tw-items-end tw-flex-grow">
										<div>
											<Field type="dynamic" className="tw-flex-grow" initValue={ initialCountColumbus } value={ initialCountColumbus === 0 ? '' : formatNumber(initialCountColumbus) } id={`count_field_${product.id}_${index}1`} data-column="Columbus" data-product={ product.id } label='<span class="tw-text-primary">Columbus</span>&nbsp;Count' placeholder="#" onChange={ handleUpdate } />
										</div>
										<div>
											<Field type="dynamic" className="tw-flex-grow" initValue={ initialCountColumbusRefurb } value={ initialCountColumbusRefurb === 0 ? '' : formatNumber(initialCountColumbusRefurb) } id={`count_field_${product.id}_${index}2`} data-column="Columbus Refurb" data-product={ product.id } label='<span class="tw-text-primary">Columbus</span>&nbsp;Refurb Count'  placeholder="#" onChange={ handleUpdate } />
										</div>
										<div>
											<Field type="dynamic" className="tw-flex-grow" initValue={ initialCountElkhart } value={ initialCountElkhart === 0 ? '' : formatNumber(initialCountElkhart) } id={`count_field_${product.id}_${index}3`} data-column="Elkhart" data-product={ product.id } label='<span class="tw-text-primary">Elkhart</span>&nbsp;Count' placeholder="#" onChange={ handleUpdate } />
										</div>
										<div>
											<Field type="dynamic" className="tw-flex-grow" initValue={ initialCountElkhartRefurb } value={ initialCountElkhartRefurb === 0 ? '' : formatNumber(initialCountElkhartRefurb) } id={`count_field_${product.id}_${index}4`} data-column="Elkhart Refurb" data-product={ product.id } label='<span class="tw-text-primary">Elkhart</span>&nbsp;Refurb Count' placeholder="#" onChange={ handleUpdate } />
										</div>
									</div>

									<div className="tw-w-full tw-pt-10px">
										<Field type="textarea" className="tw-flex-grow" initValue={ initialNotes } value={ initialNotes || "" } id={`count_field_${product.id}_${index}5`} data-column="Notes" data-product={ product.id } label='<span>Notes</span>'  placeholder="Add any notes (if needed)" onChange={ handleUpdate } />
									</div>
								</div>

								<div className="tw-flex tw-flex-col md:tw-flex-row md:tw-justify-end tw-pt-10px">
									<ButtonPrimary className="tw-w-full md:tw-w-auto" default onClick={ handleSave } data-product={ product.id }>
										<span className="tw-inline-flex tw-text-4 tw-px-20px tw-py-5px tw-min-h-30px tw-min-w-120p tw-w-full tw-text-center tw-justify-center">
											<span>
												Save
											</span>
										</span>
									</ButtonPrimary>
									<div className="tw-pointer-events-none tw-select-none tw-flex tw-px-10px tw-justify-center tw-text-dynamic tw-tracking-wider tw-leading-none tw-uppercase tw-font-quaternary tw-font-quaternary-lite md:tw-hidden" style={{ '--min-font-size': 8, '--max-font-size': 12 }}>
										<br />
										{ product.title }
									</div>
								</div>
							</div>
						}) : <>
							No results
						</> }
					</div> }
					{/* <div className="tw-fixed tw-w-full tw-left-0px tw-flex tw-px-40px tw-py-20px tw-justify-center tw-top-[100vh] -tw-translate-y-full tw-z-3 tw-bg-white tw-border-1px tw-border-black">
						<Button>
							Save
						</Button>
					</div> */}
				</form>
			</section>
		</div>
	</>
}
