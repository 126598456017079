export const isMobileBP = window.matchMedia("(max-width: 47.5rem)").matches;

export const generateUUID = () => {
	let d = new Date().getTime();
	if (window.performance && typeof window.performance.now === "function") {
		d += performance.now(); //use high-precision timer if available
	}
	const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
		// eslint-disable-next-line no-bitwise
		const r = (d + Math.random() * 16) % 16 | 0;
		// eslint-disable-next-line no-bitwise, no-mixed-operators
		d = Math.floor(d / 16);
		// eslint-disable-next-line no-bitwise, no-mixed-operators
		return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
	});
	return uuid;
}


export const getObjectByIndex = (obj, index) => obj[index];

export const uuid = () => "00-0-0-0-000".replace(/0/g, () => Math.random().toString(16).substring(2, 4));

export const placeholder = args => `https://via.placeholder.com/${args || 150}`;

export const rem = num => `${parseInt(num.replaceAll('px', '')) / 16}rem`.toString();

export const debounce = (func, time) => {
	time = time || 100;
	let timer;

	return (event) => {
		if (timer) clearTimeout(timer);
		timer = setTimeout(func, time, event);
	};
};

export const setVH = () => {
	const vh = window.innerHeight;
	const vw = window.innerWidth;
	document.documentElement.style.setProperty("--vh", `${vh}px`);
	document.documentElement.style.setProperty("--vw", `${vw}px`);
};

export const resizeVH = () => {
	setVH();
	if (window) window.addEventListener("resize", debounce(setVH, 30));
}

export const vh = () => resizeVH();

export const localTime = (date) => {
	const local = new Date(date);
	return local;
};

export const timeDiff = (date1, date2, isMinutes = false) => {
	const diff = Math.abs(localTime(date1) - localTime(date2));
	const minutesOrHours = isMinutes ? Math.floor(diff / 1000 / 60) : Math.floor(diff / 1000 / 60 / 60);
	return minutesOrHours;
};



export const getNumberFromColumn = (column) => {
	let result = 0;
	let power = 0;
	for (let i = column.length - 1; i >= 0; i--) {
		result += (column.charCodeAt(i) - 64) * Math.pow(26, power);
		power++;
	}
	return result;
}


export const getColumnFromNumber = (number) => {
	let result = '';
	while (number > 0) {
		let remainder = number % 26;
		if (remainder === 0) {
			remainder = 26;
		}
		result = String.fromCharCode(remainder + 64) + result;
		number = Math.floor((number - remainder) / 26);
	}
	return result;
}


export const getPropertyFromId = (data, id, property = "title") => {
	if (!id) return;
	return data.find(item => item.id === id)[property];
}


export const wrapText = (text, string, tag = 'span') => {
	if (!text || !string) return;
    let newString = text.replaceAll(string, `<${tag}>${string}</${tag}>`);
    return newString;
}

export const wrapTextArray = (text, strings, tag = 'span') => {
	if (!text || !strings) return;
	let newString = text;
	strings.forEach(string => {
		newString = newString.replaceAll(string, `<${tag}>${string}</${tag}>`);
	});
	return newString;
}


export const stringToArray = (string, seperator = '|') => {
    return string.split(seperator)
}
