const Base = props => {
	const Tag = props.tag || 'span';
	const attributes = {
		...props,
		tag: null
	}

	return (
		<Tag { ...attributes }>
			{ props.children }
		</Tag>
	);
}

const coreHeaders = `tw-font-quaternary tw-font-quaternary-ultra tw-uppercase tw-leading-none tw-tracking-tight`

export const PageHeader = props => {
	const Tag = props.tag || 'h1';
	const attributes = {
		...props,
		tag: null,
		className: null
	}

	return <Base { ...attributes } tag={ Tag } className={`${ coreHeaders } tw-text-2${ props.className ? " || " + props.className : '' }`}>
		{ props.children }
	</Base>
}

export const SectionHeader = props => {
	const Tag = props.tag || 'h2';
	const attributes = {
		...props,
		tag: null,
		className: null
	}

	return <Base { ...attributes } tag={ Tag } className={`${ coreHeaders } tw-text-3${ props.className ? " || " + props.className : '' }`}>
		{ props.children }
	</Base>
}

export const ArticleHeader = props => {
	const Tag = props.tag || 'h4';
	const attributes = {
		...props,
		tag: null,
		className: null
	}

	return <Base { ...attributes } tag={ Tag } className={`${ coreHeaders } tw-text-4${ props.className ? " || " + props.className : '' }`}>
		{ props.children }
	</Base>
}

export const Paragraph = props => {
	const Tag = props.tag || 'p';
	const attributes = {
		...props,
		tag: null,
		className: null
	}

	return <Base { ...attributes } tag={ Tag } className={`tw-font-quaternary tw-font-quaternary-normal tw-leading-light tw-tracking-tight tw-text-5${ props.className ? " || " + props.className : '' }`}>
		{ props.children }
	</Base>
}
