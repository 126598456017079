import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { generateUUID } from '../utils/global';
import { digitsOnly, formatNumber } from '../utils/formatting';
import { ButtonLight } from './ctas';

const coreFieldStyles = `tw-outline-none tw-border-1px tw-shadow-none tw-border-secondary tw-rounded-none tw-text-secondary focus:tw-text-black tw-q tw-font-quaternary tw-font-quaternary-bold tw-tracking-tight || tw-transition-all tw-duration-200`;

export const Label = props => {
	const Tag = props.tag || 'label';
	const attributes = {
		...props,
		tag: null,
		htmlFor: null,
		for: null,
		className: null,
		disabled: null,
		children: null
	}

	return <>
		{ props.default ? <>
			<Tag { ...attributes } htmlFor={ props.htmlFor || props.for || null } className={`tw-text-5 tw-font-quaternary tw-font-quaternary-bold tw-leading-none tw-tracking-tight tw-uppercase tw-cursor-pointer tw-inline-flex${ props.className ? " || " + props.className : '' }${ props.disabled ? ' tw-opacity-50 tw-select-none tw-pointer-events-none' : '' }`}>
				{ props.children }
			</Tag>
		</> : <>
			<Tag { ...attributes } htmlFor={ props.htmlFor || props.for || null } className={`tw-text-5 tw-font-quaternary tw-font-quaternary-bold tw-leading-none tw-tracking-tight tw-uppercase tw-cursor-pointer tw-inline-flex${ props.className ? " || " + props.className : '' }${ props.disabled ? ' tw-opacity-50 tw-select-none tw-pointer-events-none' : '' }`} dangerouslySetInnerHTML={{ __html: props.children }} />
		</> }
	</>
}



export const DynamicInput = props => {
	const ref = useRef(null);
	const [value, setValue] = useState(props.value || '');
	const [newValue, setNewValue] = useState(props.value || '');
	const [initValue, setInitValue] = useState(props.initValue || '');
	const attributes = {
		...props,
		className: null,
		type: null,
		initValue: null
	}

	const handleClick = e => {
		e.preventDefault();
		const field = ref.current;
		const increment = Number(e.target.dataset.increment);
		let currentValue = Number(digitsOnly(ref.current.value)) || 0;
		const newValue = currentValue === 0 ? increment : currentValue + increment;

		setValue(formatNumber(newValue) || "0");
		field.value = formatNumber(newValue) || "0";
	}

	useEffect(() => {
		setNewValue(value);
	}, [value]);

	const handleOnChange = e => {
		if (props.onChange) props.onChange(e);
		setValue(e.target.value);
	}

	useLayoutEffect(() => {
		if (props.value) {
			setNewValue(props.initValue);
			setValue(props.value);
		}
		if (props.initValue) setInitValue(props.initValue);
	}, [props.value, props.initValue]);

	return <>
		<div className="tw-flex">
			<div className="tw-flex-grow tw-flex">
				<input ref={ ref } { ...attributes } data-value={ props.initValue || null } value={ value } onChange={ handleOnChange } className={`tw-border-r-0px tw-w-full tw-flex || ${ coreFieldStyles } || tw-indent-10p tw-px-0px tw-py-10p placeholder:tw-text-[.5em] md:placeholder:tw-text-[.65em]${ props.className ? " || " + props.className : '' }${ props.disabled ? ' tw-opacity-50 tw-select-none tw-pointer-events-none' : '' }`} type="tel" />
			</div>
			<div className="tw-flex tw-flex-col tw-w-40px tw-flex-shrink-0">
				<ButtonLight className="tw-border-b-0px tw-h-30px md:tw-h-auto tw-w-40px md:tw-min-w-30px tw-items-center tw-justify-center tw-duration-200 tw-transition-all" data-increment="1" onClick={ handleClick }>
					+
				</ButtonLight>
				<ButtonLight className="tw-w-40px tw-h-30px md:tw-h-auto md:tw-min-w-30px tw-items-center tw-justify-center tw-duration-200 tw-transition-all" data-increment="-1" onClick={ handleClick }>
					-
				</ButtonLight>
			</div>
		</div>
		{ initValue && <>
			<div className="tw-text-5 tw-font-quaternary tw-font-quaternary-normal tw-uppercase">
				<span className="tw-opacity-70">Initial: <b className="tw-font-quaternary-bold">{ formatNumber(digitsOnly(initValue)) }</b></span>
				{ newValue && <>
					<span className={`tw-font-quaternary-light tw-transition tw-duration-200 tw-transition-all tw-opacity-0 ${ (Number(digitsOnly(newValue)) - Number(digitsOnly(initValue))) !== 0 ? `!tw-opacity-100` : '' } `}>
						{ initValue && value ? <>
							{` `}
							(<b className={`tw-font-quaternary-bold ${ (Number(digitsOnly(newValue)) - Number(digitsOnly(initValue))) > 0 ? "tw-text-accent-accent" : "tw-text-accent-dark" }`}>
								{ (Number(digitsOnly(newValue)) - Number(digitsOnly(initValue))) > 0 ? "+" : "" }
								{  formatNumber(Number(digitsOnly(newValue)) - Number(digitsOnly(initValue)))  }
							</b>)
						</> : <>N/A</> }
					</span>
				</> }
			</div>
		</> }
	</>
}

export const Textarea = props => {
	const [value, setValue] = useState(props.value || '');
	const ref = useRef(null);
	const attributes = {
		...props,
		className: null,
		type: null,
		reset: null
	}

	const handleResize = (el) => {
		if (!el) return;
		el.style.minHeight = "auto";
		el.style.minHeight = (el.scrollHeight) + "px";
	}

	const handleOnChange = e => {
		if (props.onChange) props.onChange(e);

		if (!props.reset || props.reset === null) {
			handleResize(e.target)
		}

		setValue(e.target.value);
	}

	useLayoutEffect(() => {
		if (value && ref) handleResize( ref.current );
	}, [value]);

	useLayoutEffect(() => {
		if (props.value) setValue(props.value);
	}, [props.value]);

	return <>
		<textarea ref={ ref } { ...attributes } onChange={ handleOnChange } className={`${ coreFieldStyles } || tw-px-10px tw-resize-none tw-py-10p placeholder:tw-text-[.5em] md:placeholder:tw-text-[.65em]${ props.className ? " || " + props.className : '' }${ props.disabled ? ' tw-opacity-50 tw-select-none tw-pointer-events-none' : '' }`} value={ value } />
	</>
}



export const Input = props => {
	const [value, setValue] = useState(props.value || '');
	const attributes = {
		...props,
		className: null,
		type: null,
		datalist: null
	}

	const handleOnChange = e => {
		if (props.onChange) props.onChange(e);
		setValue(e.target.value);
	}

	useLayoutEffect(() => {
		if (props.value) setValue(props.value);
	}, [props.value]);

	return <>
		<input { ...attributes } list={ props.datalist ? `${ props.id }_datalist` : null } value={ value } onChange={ handleOnChange } className={`${ coreFieldStyles } || tw-indent-10p tw-px-0px tw-py-10p placeholder:tw-text-[.5em] md:placeholder:tw-text-[.65em]${ props.className ? " || " + props.className : '' }${ props.disabled ? ' tw-opacity-50 tw-select-none tw-pointer-events-none' : '' }`} type={ props.type || 'text' } />
		{ props.datalist && <>
			<datalist id={`${ props.id }_datalist`}>
				{ props.datalist.map((item, index) => {
					return <option key={`${ props.id }_datalist_${index}`} value={ item }></option>
				}) }
			</datalist>
		</> }
	</>
}


export const Field = props => {
	const Tag = props.tag || 'div';
	const id = props.id || generateUUID();
	const name = props.name || id || null;
	const value = props.value ? props.value : '';


	const attributes = {
		...props,
		className: null,
		name: null,
		id: null,
		htmlFor: null,
		for: null,
		placeholder: null,
		disabled: null,
		label: null,
		title: null,
		value: null,
		type: null,
		'data-value': null,
		initValue: null,
		format: null,
	}

	const inputAttributes = {
		...props,
		className: null,
		htmlFor: null,
		for: null
	}

	return <>
		<Tag { ...attributes } className={`tw-flex tw-flex-col tw-w-full${ props.className ? " || " + props.className : '' }`}>
			{ props.label || props.title ? <>
				<Label for={ id } disabled={ props.disabled || null } className="tw-w-full tw-pb-3p">
					{ props.label || props.title }
				</Label>
			</> : <></> }
			{ props.children ? <>
				{ props.children }
			</> : <>
				{ props.type === 'dynamic' ? <>
					<DynamicInput format={ props.format || null } { ...inputAttributes } value={ value } id={ id } name={ name } placeholder={ props.placeholder || null } disabled={ props.disabled || null } />
				</> : props.type === 'textarea' ? <>
					<Textarea format={ props.format || null } { ...inputAttributes } type={ null } value={ value } id={ id } name={ name } placeholder={ props.placeholder || null } disabled={ props.disabled || null } />
				</> : <>
					<Input format={ props.format || null } { ...inputAttributes } value={ value } id={ id } name={ name } placeholder={ props.placeholder || null } disabled={ props.disabled || null } />
				</> }

			</>}
		</Tag>
	</>
}
