import React, { createContext, useEffect, useState } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

const googleApiClientId = process.env.REACT_APP_GOOGLE_API_OAUTH_2_CLIENT_ID;

// Pull the persisted currentUser from local storage on load if it exists
const savedUser = localStorage.getItem('currentUser');

export const AuthProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState(JSON.parse(savedUser));

	useEffect(() => {
		if (currentUser) {
			localStorage.setItem('currentUser', JSON.stringify(currentUser));
		} else {
			localStorage.setItem('currentUser', null);
		}
	}, [currentUser]);

	return (
		<AuthContext.Provider value={{ currentUser, setCurrentUser }}>
			<GoogleOAuthProvider clientId={googleApiClientId}>
				{children}
			</GoogleOAuthProvider>
		</AuthContext.Provider>
	);
};

export const AuthContext = createContext();
