import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useGoogleSheet } from "../../../hooks/useGoogleSheet";
import { formatDate, nameInitials } from "../../../utils/formatting";
import { AspectImage } from "../../../components/assets";
import { ButtonLight, ButtonPrimary } from "../../../components/ctas";

const ResultItem = ({ results }) => {
	const [sheetRow, setSheetRow] = useState(null); // used if from filtered results
	const [ready, setReady] = useState(false);

	useLayoutEffect(() => {
		if (results?.get('Serial Number')) {
			setSheetRow({
				"index": results._rowNumber,
				"Serial_Number": results.get('Serial Number'),
				"Model": results.get('Model'),
				"Modified_Date": results.get('Modified Date'),
				"Modified_By": results.get('Modified By'),
				"Start_Time": results.get('Start Time'),
				"Exhaust_Plug_Installed": results.get('Exhaust Plug Installed'),
				"Exhaust_Plug_Installed_Details": results.get('Exhaust Plug Installed Details'),
				"Conduit_Properly_Installed": results.get('Conduit Properly Installed'),
				"Conduit_Properly_Installed_Details": results.get('Conduit Properly Installed Details'),
				// "Fuel_Hoses_Properly_Routed": results.get('Fuel Hoses Properly Routed'),
				"Fuel_Hoses_Properly_Routed_Details": results.get('Fuel Hoses Properly Routed Details'),
				// "V2_Fuel_Pump_Replaced_Installed": results.get('V2 Fuel Pump Replaced Installed'),
				// "V2_Fuel_Pump_Replaced_Installed_Details": results.get('V2 Fuel Pump Replaced Installed Details'),
				"Firmware_Across_All_Device_At_Current_Version": results.get('Firmware Across All Device At Current Version'),
				"Firmware_Across_All_Device_At_Current_Version_Details": results.get('Firmware Across All Device At Current Version Details'),
				"Fuel_Pump_Module_Flashed": results.get('Fuel Pump Module Flashed'),
				"Fuel_Pump_Module_Flashed_Details": results.get('Fuel Pump Module Flashed Details'),
				"All_Connectors_Securely_Attached": results.get('All Connectors Securely Attached'),
				"All_Connectors_Securely_Attached_Details": results.get('All Connectors Securely Attached Details'),
				"Correct_Amount_Of_Oil_Installed": results.get('Correct Amount Of Oil Installed'),
				"Correct_Amount_Of_Oil_Installed_Details": results.get('Correct Amount Of Oil Installed Details'),
				// "Second_Hole_Drilled_And_Screen_Installed": results.get('Second Hole Drilled And Screen Installed'),
				// "Second_Hole_Drilled_And_Screen_Installed_Details": results.get('Second Hole Drilled And Screen Installed Details'),
				"Isolators_Installed": results.get('Isolators Installed'),
				"Isolators_Installed_Details": results.get('Isolators Installed Details'),
				"All_Hardware_Reinstalled": results.get('All Hardware Reinstalled'),
				"All_Hardware_Reinstalled_Details": results.get('All Hardware Reinstalled Details'),
				"All_Wires_And_Hoses_Secured_With_Zip_Ties": results.get('All Wires And Hoses Secured With Zip Ties'),
				"All_Wires_And_Hoses_Secured_With_Zip_Ties_Details": results.get('All Wires And Hoses Secured With Zip Ties Details'),
				"Tested_For_Operation_And_Output": results.get('Tested For Operation And Output'),
				"Tested_For_Operation_And_Output_Details": results.get('Tested For Operation And Output Details'),
				"Remote_Harness_Checked_For_Operation": results.get('Remote Harness Checked For Operation'),
				"Remote_Harness_Checked_For_Operation_Details": results.get('Remote Harness Checked For Operation Details'),
				"Units_Properly_Stacked": results.get('Units Properly Stacked'),
				"Units_Properly_Stacked_Details": results.get('Units Properly Stacked Details'),
				"Units_Properly_Wrapped_For_Shipping": results.get('Units Properly Wrapped For Shipping'),
				"Units_Properly_Wrapped_For_Shipping_Details": results.get('Units Properly Wrapped For Shipping Details'),
				"Units_Cleared_To_Ship": results.get('Units Cleared To Ship'),
				"Units_Cleared_To_Ship_Details": results.get('Units Cleared To Ship Details'),
				"Photo": results.get('Photo'),
				"Serial_Photo": results.get('Serial Photo'),
				"PO": results.get('PO'),
				"Notes": results.get('Notes'),
				"OEM": results.get('OEM'),
				"Ship_Date": results.get('Ship Date'),
				"Units_Properly_Stacked": "",
				"Units_Properly_Wrapped_For_Shipping": "",
				"Units_Cleared_To_Ship": "",
			});

			setTimeout(() => {
				setReady(true)
			}, 50);
		} else if (results?.Serial_Number) {
			setSheetRow(results);
			setReady(true)
		}
	}, [results]);

	return ready ? <>
		<div className="tw-table-row tw-w-full">
			<div className="tw-table-cell tw-w-full">
				<div className="tw-bg-white tw-text-black tw-font-quaternary  tw-uppercase tw-text-[16px] tw-overflow-clip tw-flex tw-flex-col">
					<div className="tw-relative tw-w-full tw-flex tw-items-center">
						<div className="tw-flex-shrink-0">
							<AspectImage className="tw-w-full tw-max-w-[200px] tw-flex-shrink-0" src={ `/images/rvmp-logo_with_flex-power.svg` } aspect={ 295 / 72 } />
							<h1 className=" tw-font-quaternary-bold tw-tracking-tight tw-text-[12px] tw-absolute tw-left-[60px] tw-top-[38px]">
								RVMP Quality Control Checklist
							</h1>
						</div>
						<span className="tw-font-quaternary-normal tw-text-[12px] tw-ml-auto tw-text-right">
							Tag #&nbsp;
							<span className="tw-font-quaternary-bold tw-text-[12px] tw-font-mono">
								00{ formatDate(sheetRow.Modified_Date, 'yyyymmdd', '').substring(2,4) }2{ sheetRow.index < 10 ? `0${ sheetRow.index }` : sheetRow.index }
							</span>
							<br />
							<span className="tw-font-quaternary-normal tw-text-[10px]">Today's Date:&nbsp;</span>
							<span className="tw-font-quaternary-bold tw-text-[10px] tw-font-mono">
								{ formatDate(sheetRow.Modified_Date) }
							</span>
						</span>
					</div>
					<div className="tw-table tw-w-full tw-border-y-2px tw-border-y-black tw-py-10px tw-mt-10px">
						<div className="tw-table-row tw-w-full">
							<div className="tw-table-cell tw-text-[10px] tw-w-[60px]">
								<span>
									Inspector:
								</span>
							</div>
							<div className="tw-table-cell tw-normal-case tw-text-[12px] tw-font-quaternary-bold">
								<span>
									{ sheetRow.Modified_By }
								</span>
							</div>
							<div className="tw-table-cell tw-text-[10px]">
								<span>
									OEM:
								</span>
							</div>
							<div className="tw-table-cell  tw-w-[85px] tw-normal-case tw-text-[10px] tw-text-right">
								<span>
									{ sheetRow.OEM }
								</span>
							</div>
						</div>
						<div className="tw-table-row tw-w-full">
							<div className="tw-table-cell tw-w-[60px] tw-text-[10px]">
								<span>
									OEM P.O.:
								</span>
							</div>
							<div className="tw-table-cell  tw-normal-case tw-text-[10px] tw-font-quaternary-normal">
								<span>
									{ sheetRow.PO }
								</span>
							</div>
							<div className="tw-table-cell tw-w-[85px] tw-text-[10px]">
								<span>
									Ship Date:
								</span>
							</div>
							<div className="tw-table-cell tw-normal-case tw-text-[10px] tw-w-[85px] tw-font-quaternary-normal  tw-text-right">
								<span>
									{ sheetRow.Ship_Date }
								</span>
							</div>
						</div>
						<div className="tw-table-row tw-w-full">
							<div className="tw-table-cell tw-w-[60px] tw-text-[10px]">
								<span>
									Model:
								</span>
							</div>
							<div className="tw-table-cell  tw-normal-case tw-text-[10px] tw-font-quaternary-normal ">
								<span>
									Flex Power { sheetRow.Model }
								</span>
							</div>
							<div className="tw-table-cell tw-w-[85px] tw-text-[10px]">
								<span>
									Serial Number:
								</span>
							</div>
							<div className="tw-table-cell  tw-normal-case tw-text-[10px] tw-font-quaternary-normal tw-text-right">
								<span>
									{ sheetRow.Serial_Number }
								</span>
							</div>
						</div>
					</div>
					<div className="tw-divide-y-1px  tw-divide-black tw-text-[12px]">
						{ Object.entries(sheetRow).map(([key, value]) => {
							return (value && !key.includes("_Details") && key !== 'index' && key !== 'Serial_Number' && key !== 'Model' && key !== 'Modified_Date' && key !== 'Modified_By' && key !== 'Start_Time' && key !== 'Photo' && key !== 'Serial_Photo' && key !== 'Ship_Date' && key !== 'PO' && key !== 'Notes' && key !== 'OEM') && <div key={`${ key }`} className="tw-flex tw-items-center tw-divide-x-1px tw-divide-black">
								<div className="tw-flex-grow tw-inline-flex tw-items-center tw-pt-5px">
									{ key.replaceAll("_", " ") }
								</div>
								<div className="tw-flex-shrink-0 tw-w-50px tw-text-center tw-inline-flex tw-items-center tw-justify-center tw-py-6px tw-text-[14px] tw-font-quaternary-bold">
									{ value === "Yes" ? <span>  { nameInitials(sheetRow.Modified_By) } </span> : value === "" ? <span>&nbsp;</span> : value === "No" ? <span className="tw-lowercase tw-font-quaternary-light">x</span> : value === "N/A" ? <span className="tw-font-quaternary-light tw-text-[.75em]">N/A</span> : value }
								</div>
							</div>
						}) }
						<div className="tw-flex tw-items-center tw-w-full tw-flex-grow tw-flex tw-flex-col">
							<div className="tw-flex-grow tw-inline-flex tw-items-center tw-flex-grow tw-flex tw-w-full tw-items-end tw-pt-15px tw-font-quaternary-bold tw-border-t-1px tw-border-black">
								Authorized to Ship By:
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</> : <>
		<div>
			Loading...
		</div>
	</>

}

const QCPrint = props => {
	const location = useLocation();
	const { loadSheet, sheetRows } = useGoogleSheet({ spreadsheetId: process.env.REACT_APP_QC_SPREADSHEET_ID });
	const { ID } = useParams();
	const [results, setResults] = useState(null);
	const [filtersResults, setFiltersResults] = useState(null);
	const [rows, setRows] = useState(null);
	const [filterValue, setFilterValue] = useState(null);


	const filterData = (searchTerm) => { // partial matches
		let search = searchTerm.toLowerCase();
		// let acc = [];

		return rows.reduce((acc, curr)  => {
			if (searchTerm === "kevinmackrules") {
				if (curr.get('Hide') !== 'TRUE') {
					acc.push(curr);
				}
			} else {
				if (curr.get('Serial Number')?.toLowerCase().includes(search)) {
					if (curr.get('Hide') !== 'TRUE') {
						acc.push(curr);
					}
				}

				if (curr.get('PO')?.toLowerCase().includes(search)) {
					if (curr.get('Hide') !== 'TRUE') {
						acc.push(curr);
					}
				}
				// if (curr.get('Serial Number').toLowerCase().includes(search)) {
				// 	if (curr.get('Hide') !== 'TRUE') {
				// 		acc.push(curr);
				// 	}
				// }
			}
			return acc;
		}, []);
	}

	const printScreen = (e, goBack = false) => {
		if (e) e.preventDefault();

		window.print();

		if (goBack) {
			setTimeout(() => {
				window.history.back();
			}, 100);
		}
	}

	// useEffect(() => {
	// 	if (results) {
	// 		setTimeout(() => {
	// 			printScreen(null, true)
	// 		}, 1000);
	// 	}
	// }, [results]);

	useLayoutEffect(() => {
		const sheets = loadSheet();
	}, []);

	useEffect(() => {
		// for "print all" from QC page functionality (part 2)
		if (rows && rows.length > 0 && filterValue) {
			setFiltersResults(filterData(filterValue))
		}
	}, [rows, filterValue]);

	useEffect(() => {
		// for "prtint all" from QC page functionality (part 1)
		if (location?.state?.filterValue) {
			const sheets = loadSheet(true).then((sheets) => {
				setRows(sheets);
			});
			setFilterValue(location?.state?.filterValue);
			setTimeout(() => {
				setResults(true)
			}, 500);
		}

		if (ID) {
			const sheets = loadSheet(true).then((sheets) => {
				setRows(sheets);
			});
			setFilterValue(ID);
			setTimeout(() => {
				setResults(true)
			}, 500);
		}


	}, [location?.state?.filterValue])

	return <>
		<div className="tw-w-full tw-max-w-1440px tw-px-40px tw-flex tw-justify-center tw-py-60px c-print">
			<ButtonPrimary default onClick={ printScreen }>
				Print Now
			</ButtonPrimary>
		</div>
		{ filtersResults && filtersResults.length > 0 ? <>
			{ filtersResults.map((result, index) => {
				return <div key={`result_${index}`} className="tw-w-full c-print-item  tw-table">
					<ResultItem results={ result } />
				</div>
			}) }
		</> : results ? <>
			<div className="c-print-item tw-table tw-w-full">
				<ResultItem {...results} />
			</div>
		</>  : <>
			<div className="tw-w-full tw-flex tw-justify-center">
				<ButtonPrimary default to="/dashboard/qc/completed">
					Go Back To Results
				</ButtonPrimary>
			</div>
		</> }

		<style>
			{`
				/*@page {
					size: C6;
					margin: 0;
				}*/


				@media print {
					* {
						box-sizing: content-box;
					}
					header {
						display: none;
					}
					body, html, main {
						background: transparent !important;
						margin: 0 !important;
						padding: 0 !important;
						height: auto !important;
						min-height: 0px !important;
						max-height: none !important;
					}
				}
			`}
		</style>
	</>
}

export default QCPrint;
