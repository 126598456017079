
import { Link } from "react-router-dom"
import Wrapper from "./wrappers"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { debounce, timeDiff, vh } from "../utils/global"
import { useAuth } from "../hooks/useAuth"
import { useAppContext } from "../contexts/app"
import { AspectImage } from "./assets"

export const LogoutLink = ({ base, currentUser, logout }) => {
	const { timeRemaining } = useAppContext();

	return <Link to="/" onClick={logout} title={`Logout of ${ currentUser.userinfo?.name }`}>
		<span className="tw-flex tw-items-center tw-w-full tw-space-x-5px">
			<span>Logout</span>
			{ base ? <></> : <span className="tw-text-[.7em] tw-flex tw-flex-col tw-leading-none tw-items-center"><b className="">{ timeRemaining }</b> <span className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-[0.4375rem]">minutes <span>remaining</span></span></span> }
		</span>
	</Link>
}

export const Header = ({tout, ...props}) => {
	const headerRef = useRef(null);
	const { timeRemaining } = useAppContext();
	const { currentUser, logout } = useAuth();

	const setHeaderHeight = (el) => {
		const header = el || document.querySelector("#header");
		document.documentElement.style.setProperty("--header-height", `${(header)?.offsetHeight || 0}px`);
	}

	useEffect(() => {
		if (headerRef?.current) {
			setTimeout(() => {
				setHeaderHeight(headerRef?.current)
			}, 0);
			if (window) window.addEventListener("resize", debounce(setHeaderHeight(headerRef?.current), 30));
		}
	}, [headerRef]);

	return <>
		{ tout && currentUser && <>
			{ Number(timeRemaining) > 1 && <>
				<div className=" tw-bg-accent-dark tw-justify-center tw-text-white tw-text-4 tw-py-10px tw-px-20px tw-text-center tw-fixed tw-w-full tw-top-0 tw-right-0 tw-left-0 tw-z-10">
					<p>
						{ tout }
					</p>
					<div className="tw-flex tw-w-full tw-justify-center tw-uppercase tw-font-quaternary tw-font-quaternary-black">
						<LogoutLink base currentUser={ currentUser } logout={ logout } />
					</div>
				</div>
			</> }
		</> }
		<header ref={ headerRef } id="header" className="tw-bg-black tw-border-b tw-border-b-white/10 tw-text-white tw-select-none tw-shadow-sm">
			<Wrapper className="tw-py-20px tw-space-x-40d [&>a]:tw-w-full [&_a]:tw-inline-flex [&_a]:tw-text-inherit [&_a]:tw-items-center [&_a]:tw-justify-center [&_a]:tw-text-white [&_a]:tw-transition-all [&_a]:tw-duration-200 hover:[&_a]:tw-text-primary tw-leading-[.9] tw-font-quaternary tw-font-quaternary-black tw-tracking-tight tw-uppercase" tag="nav">
				<div className="tw-flex tw-gap-40d">
					<div className="[&_a]:tw-inline-flex tw-max-w-70px tw-flex-shrink-0">
						<Link to="/">
							<AspectImage src={`/images/rvmp-logo-bordered.svg`} alt="RVMP Logo" className="tw-w-full" aspect="1/1" />
						</Link>
					</div>
					<ul className="tw-flex-grow tw-flex [&>li]:tw-inline-flex tw-text-dynamic tw-gap-10d md:tw-gap-40d" style={{ '--min-font-size': 10, '--max-font-size': 20 }}>
						<li>
							<Link to="/dashboard/inventory">
								Inventory Tracker
							</Link>
						</li>
						<li>
							<Link to="/dashboard/weight-dims">
								Size &amp; Weight
							</Link>
						</li>
						{/* <li>
							<Link to="/dashboard/order-labels">
								Order Labels
							</Link>
						</li> */}
						<li className="tw-ml-auto">
							<span className="tw-flex">
								{ currentUser && <LogoutLink currentUser={ currentUser } logout={ logout } /> }
							</span>
						</li>
					</ul>
				</div>
			</Wrapper>
		</header>
	</>
}

export const Base = props => {
	const { logout } = useAuth();
	const { currentTime, loggedTime, setCurrentTime, setTimeRemaining } = useAppContext();
	const  [tout, setTout] = useState(null);

	setTimeout(() => {
		setCurrentTime(new Date().toISOString())
	}, 58000);

	useLayoutEffect(() => {
		setCurrentTime(new Date().toISOString())
		vh();
	}, []);

	useEffect(() => {
		if ( currentTime && loggedTime ) {
			const timeDifference = timeDiff(currentTime, loggedTime, true);

			if (localStorage.getItem('timeDifference') === null) {
				localStorage.setItem('timeDifference', 59 - timeDifference);
				setTimeRemaining(59 - timeDifference);
			} else {
				setTimeRemaining(Number(localStorage.getItem('timeDifference')) - timeDifference);
			}

			if (timeDifference >= 54) {
				setTout(`You must login again within the next ${ 59 - timeDifference } minutes`);
			}

			if (timeDifference >= 59) {
				setTout(null);
				logout();
			}
		}
	}, [currentTime, loggedTime]);

	return <>
		<div className="tw-flex tw-flex-col tw-min-h-screen tw-w-full tw-overflow-x-clip">
			<Header tout={ tout || null }  />
			<Wrapper className="tw-py-40px tw-flex-grow" tag="main">
				{ props.children }
			</Wrapper>
		</div>
	</>
}

export default Base;
