import { Link } from "react-router-dom";

export const Button = props => {
	const Tag = props.tag || 'button';

	const attributes = {
		...props,
		className: null
	}

	return <>
		<Tag { ...attributes } className={`tw-outline-none tw-border-1px tw-rounded-none tw-shadow-none tw-inline-flex ${ props.default ? ' || tw-leading-none tw-items-center tw-justify-center tw-transition-all tw-duration-200 tw-uppercase tw-font-quaternary tw-font-quaternary-bold tw-tracking-tight tw-select-none' : '' }${ props.className ? " || " + props.className : '' }`}>
			<span className={`tw-pointer-events-none${ props.default ? ' || tw-px-20px tw-py-7px' : '' }`}>
				{ props.children }
			</span>
		</Tag>
	</>
}


export const ButtonPrimary = props => {
	const tag = props.tag || props.href ? 'a' : 'button';

	const attributes = {
		...props,
		className: null,
		default: null,
		tag: null,
		to: null
	}

	return (props.to) ? <>
		<Link to={ props.to }>
			<Button { ...attributes } tag="span" default={ props.default || null } className={`tw-bg-primary tw-text-black hover:tw-bg-secondary hover:tw-text-primary tw-border-primary hover:tw-border-secondary ${ props.className ? " || " + props.className : '' }`}>
				{ props.children}
			</Button>
		</Link>
		</> : <>
		<Button { ...attributes } tag={ tag || null } default={ props.default || null } className={`tw-bg-primary tw-text-black hover:tw-bg-secondary hover:tw-text-primary tw-border-primary hover:tw-border-secondary ${ props.className ? " || " + props.className : '' }`}>
			{ props.children}
		</Button>
	</>
}



export const ButtonSecondary = props => {
	const tag = props.tag || 'button';

	const attributes = {
		...props,
		className: null,
		default: null,
		tag: null
	}

	return <>
		<Button { ...attributes } tag={ tag || null } default={ props.default || null } className={`tw-bg-secondary tw-text-white hover:tw-bg-primary hover:tw-text-black tw-border-black ${ props.className ? " || " + props.className : '' }`}>
			{ props.children}
		</Button>
	</>
}



export const ButtonLight = props => {
	const tag = props.tag || 'button';

	const attributes = {
		...props,
		className: null,
		default: null,
		tag: null
	}

	return (props.to) ? <>
		<Link to={ props.to }>
			<Button { ...attributes } tag="span" default={ props.default || null }  className={`tw-bg-white tw-pointer-events-none tw-text-secondary hover:tw-bg-primary hover:tw-text-black tw-border-black ${ props.className ? " || " + props.className : '' }`}>
				{ props.children}
			</Button>
		</Link>
		</> : <>
			<Button { ...attributes } tag={ tag || null } default={ props.default || null } className={`tw-bg-white tw-text-secondary hover:tw-bg-primary hover:tw-text-black tw-border-black ${ props.className ? " || " + props.className : '' }`}>
				{ props.children}
			</Button>
		</>
}
