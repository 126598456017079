import { useState } from "react";
import { useAuth } from "./useAuth";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { useAppContext } from "../contexts/app";

export const useGoogleSheet = ({ spreadsheetId, token }) => {
	const { currentUser } = useAuth();
	const [spreadsheet, setSpreadsheet] = useState(null);
	const [sheetRows, setSheetRows] = useState(null);
	const { setRows } = useAppContext();

	const loadSheet = async (returnItems) => {
		const doc = new GoogleSpreadsheet(spreadsheetId, { token: token || currentUser.credentials.access_token });

		// loads document properties and worksheets
		await doc.loadInfo();
		setSpreadsheet(doc);

		const sheet = doc.sheetsByIndex[0];
		const rows = await sheet.getRows();

		setRows(rows.length);

		setSheetRows(rows);


		if (returnItems) {
			return rows;
		}
		// await doc.updateProperties({ title: 'renamed doc' });

		// const sheet = doc.sheetsByIndex[0]; // or use `doc.sheetsById[id]` or `doc.sheetsByTitle[title]`
		// console.log(sheet.title);
		// console.log(sheet.rowCount);

		// // adding / removing sheets
		// const newSheet = await doc.addSheet({ title: 'another sheet' });
		// console.log(doc);
	}

	const addRow = async (row) => {
		const doc = new GoogleSpreadsheet(spreadsheetId, { token: token || currentUser.credentials.access_token });
		await doc.loadInfo();
		const sheet = doc.sheetsByIndex[0];
		const result = await sheet.addRow(row);

		loadSheet();
	}

	const setRow = (row, header, value) => {
		row.set(header, value);
	}

	const saveRow = (row) => {
		row.save();
	}

	return { loadSheet, spreadsheet, sheetRows, setRow, saveRow, addRow };
};
